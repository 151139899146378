import {Modal, Table} from "antd";
import React, {useRef, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import Draggable from "react-draggable";

import '../projectPage.css';


const orderDetailTableColumns = [
    {title: <FormattedMessage id="inventoryPage.productCode"/>, dataIndex: 'itemId', key: 'itemId', width: 80, fixed: 'left'},
    {title: <FormattedMessage id="inventoryPage.productName"/>, dataIndex: 'productName', key: 'productName', width: 240, fixed: 'left'},
    {title: <FormattedMessage id="inventoryPage.specification"/>, dataIndex: 'specification', key: 'specification', width: 250},
    {
        title: <FormattedMessage id="inventoryPage.costPrice"/>,
        dataIndex: 'costPrice',
        key: 'costPrice',
        width: 120
    },
    {
        title: <FormattedMessage id="inventoryPage.retailPrice"/>,
        dataIndex: 'retailPrice',
        key: 'retailPrice',
        width: 120
    },
    {
        title: <FormattedMessage id="materialTable.quantity"/>,
        dataIndex: 'orderQuantity',
        key: 'orderQuantity',
        width: 120
    },
    {
        title: <FormattedMessage id="inventoryPage.totalPrice"/>,
        dataIndex: 'totalPrice',
        key: 'totalPrice',
        width: 120,
        render: (text, record) => (record.orderQuantity * record.retailPrice).toFixed(2),
    },
    {
        title: <FormattedMessage id="materialTable.progress"/>,
        dataIndex: 'progress',
        key: 'progress',
        width: 120
    },
    {title: <FormattedMessage id="inventoryPage.unit"/>, dataIndex: 'unit', key: 'unit', width: 60},
    {
        title: <FormattedMessage id="inventoryPage.vendor"/>,
        dataIndex: 'vendor',
        key: 'vendor',
        width: 150
    },
    {
        title: <FormattedMessage id="inventoryPage.memo"/>,
        dataIndex: 'memo',
        key: 'memo',
        width: 150
    },
    {
        title: <FormattedMessage id="inventoryPage.arrivalDate"/>,
        dataIndex: 'arrivalDate',
        key: 'arrivalDate',
        width: 150
    }
];


const ViewOrderDetailModal = ({orderNumber, orderDetails, visible, onClose, onExport}) => {
    const intl = useIntl();

    // Draggable modal
    const dragNodeRef = useRef(null);
    const [modalPosition, setModalPosition] = useState({x: 0, y: 0});
    const [dragDisabled, setDragDisabled] = useState(true);


    return (
        <div>
            <Modal title={
                <div style={{width: '100%', cursor: 'move'}}
                     onMouseOver={() => setDragDisabled(false)}
                     onMouseOut={() => setDragDisabled(true)}
                >
                    {intl.formatMessage({id: "materialTable.viewOrderNumber"}, {orderNumber: orderNumber})}
                </div>
            }
                   centered
                   width="60%"
                   open={visible}
                   onCancel={() => {
                       onClose();
                       setModalPosition({x: 0, y: 0});
                   }}
                   maskClosable={false}
                   footer={null}
                   modalRender={(modal) => (
                       <Draggable disabled={dragDisabled}
                                  nodeRef={dragNodeRef}
                                  position={modalPosition}
                                  onStop={(e, data) => {
                                      setModalPosition({x: data.x, y: data.y});
                                  }}
                       >
                           <div ref={dragNodeRef}>{modal}</div>
                       </Draggable>
                   )}
            >
                <div style={{maxHeight: '70vh', height: '70vh'}}>
                    <Table dataSource={orderDetails}
                           columns={orderDetailTableColumns}
                           pagination={false}
                           virtual={true}
                           scroll={{y: '55vh'}}
                           size="middle"
                    />
                </div>
            </Modal>
        </div>
    );
};

export default ViewOrderDetailModal;
