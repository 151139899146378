import React, {useEffect, useRef, useState} from 'react';
import {AutoComplete, Button, Flex, Input, Layout, Menu, message, Popconfirm, Space, Table, Tag, Typography} from 'antd';
import {
    AccountBookOutlined,
    CheckCircleOutlined,
    CreditCardOutlined,
    FundProjectionScreenOutlined,
    LogoutOutlined,
    PieChartOutlined, SearchOutlined,
    SettingOutlined,
    ShopOutlined,
    ShoppingCartOutlined,
    SyncOutlined,
} from '@ant-design/icons';
import {useNavigate} from 'react-router-dom';
import {FormattedMessage, useIntl} from "react-intl";
import DropDownMenuLanguage from "./components/DropDownMenuLanguage";
import axios from "axios";
import dayjs from 'dayjs';
import {v4 as uuidv4} from "uuid";
import {useCookies} from "react-cookie";
import OrderMaterialModal from "./components/OrderMaterialModal";
import EditOrderDetailModal from "./components/EditOrderDetailModal";

dayjs.extend(require('dayjs/plugin/utc'))
dayjs.extend(require('dayjs/plugin/timezone'))

const {Header, Content, Footer, Sider} = Layout;
const {SubMenu} = Menu;
const {Title, Link} = Typography;
const {TextArea} = Input;

function getSiderMenuItem(label, key, icon, children, type) {
    return {key, icon, children, label, type};
}

const siderMenuItems = [
    getSiderMenuItem(<FormattedMessage id="sider.dashboard"/>, 'dashboard', <PieChartOutlined/>),
    getSiderMenuItem(<FormattedMessage id="sider.projects"/>, 'projects', <FundProjectionScreenOutlined/>),
    getSiderMenuItem(<FormattedMessage id="sider.order"/>, 'order', <CreditCardOutlined/>),
    getSiderMenuItem(<FormattedMessage id="sider.inventory"/>, 'inventory', <ShopOutlined/>),
    getSiderMenuItem(<FormattedMessage id="sider.accounting"/>, 'accounting', <AccountBookOutlined/>),
    getSiderMenuItem(<FormattedMessage id="sider.settings"/>, 'settings', <SettingOutlined/>),
];


const OrderPage = ({setLocale}) => {
    const navigate = useNavigate();
    const intl = useIntl(); // Hook to get the intl object
    const tableRef = useRef(null);
    const orderTableRef = useRef(null);
    const [messageApi, contextHolder] = message.useMessage();
    const [cookies, setCookie] = useCookies(['userInfo']);
    const [userInfo, setUserInfo] = useState(cookies.userInfo || {});

    const [csrfToken, setCsrfToken] = useState(null);
    const [inventoryData, setInventoryData] = useState([]);
    const [selectMaterialType, setSelectMaterialType] = useState('Elevator Parts');
    const [isFetchingData, setIsFetchingData] = useState(false);
    const [isSubmittingOrder, setIsSubmittingOrder] = useState(false);
    const [isUpdatingOrder, setIsUpdatingOrder] = useState(false);
    const [isDeletingOrder, setIsDeletingOrder] = useState(false);

    const [projectOptions, setProjectOptions] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [selectedProjectId, setSelectedProjectId] = useState(null);
    const [orderData, setOrderData] = useState([]);
    const [orderNumber, setOrderNumber] = useState(null);
    const [filteredOrderData, setFilteredOrderData] = useState([]);

    const [showSubmitOrderModal, setShowSubmitOrderModal] = useState(false);
    const [selectedOrderNumber, setSelectedOrderNumber] = useState(null);
    const [selectedOrderDetails, setSelectedOrderDetails] = useState([]);
    const [showOrderTableEditModal, setShowOrderTableEditModal] = useState(false);  // Modal for one single order

    const [tableHeight, setTableHeight] = useState(500);
    const [exportAllOrders, setExportAllOrders] = useState(false);

    useEffect(() => {
        fetchOrderData();
        fetchProjectIdNames();
    }, []);

    useEffect(() => {
        if (exportAllOrders) {
            let selectedData;

            selectedData = selectedOrderDetails.map(item => ({
                ...item,
                totalPrice: (item.orderQuantity || 0) * (item.retailPrice || 0)
            }));
            handleOrderExport('view', selectedOrderNumber, selectedData);
            setExportAllOrders(false); // Reset the flag after exporting
        }
    }, [selectedOrderDetails, exportAllOrders]);

    const updateTableHeight = () => {
        const parentHeight = orderTableRef.current?.parentElement?.offsetHeight || window.innerHeight;
        const newTableHeight = parentHeight * 0.57;
        setTableHeight(newTableHeight);
    };

    useEffect(() => {
        updateTableHeight();
        window.addEventListener('resize', updateTableHeight);

        return () => {
            window.removeEventListener('resize', updateTableHeight);
        };
    }, []);

    // Table columns for orders
    const orderDetailColumns = [
        {title: <FormattedMessage id="materialTable.orderNumber"/>, dataIndex: 'orderNumber'},
        {title: <FormattedMessage id="orderPage.client"/>, dataIndex: 'client'},
        {
            title: <FormattedMessage id="materialTable.orderDate"/>,
            dataIndex: 'orderDate',
            width: 220,
            key: 'orderDate',
            render: (text) => dayjs.utc(text).tz('America/New_York').format('YYYY-MM-DD HH:mm:ss'),
            sorter: (a, b) => dayjs(a.orderDate).unix() - dayjs(b.orderDate).unix()
        },
        {
            title: <FormattedMessage id="materialTable.status"/>,
            dataIndex: 'status',
            key: 'status',
            render: (_, record) => (
                record.complete ? (
                    <Tag icon={<CheckCircleOutlined/>} color="success">
                        <FormattedMessage id="main.completed"/>
                    </Tag>
                ) : (
                    <Tag icon={<SyncOutlined spin/>} color="processing">
                        <FormattedMessage id="main.inProgress"/>
                    </Tag>
                )
            )
        },
        {title: <FormattedMessage id="materialTable.creator"/>, dataIndex: 'creatorName'},
        {
            title: <FormattedMessage id="main.actions"/>,
            dataIndex: 'editAction',
            key: 'editAction',
            render: (_, record) => (
                <>
                    <Link onClick={() => {
                        setSelectedProjectId(record.projectId);
                        handleSetOrderTableDetail(record.orderNumber);
                        setShowOrderTableEditModal(true);
                    }}>
                        <FormattedMessage id="main.edit"/>
                    </Link>
                    <span> | </span>
                    <Link onClick={() => {
                        handleSetOrderTableDetail(record.orderNumber);
                        setExportAllOrders(true);
                    }}>
                        <FormattedMessage id="inventoryPage.exportOrder"/>
                    </Link>
                    <span> | </span>
                    <Popconfirm title={intl.formatMessage({id: "warning.confirmDelete"})}
                                onConfirm={handleOrderDelete}
                                okButtonProps={{loading: isDeletingOrder}}
                    >
                        <Link disabled={hasProgressGreaterOrEqualOne(record.orderNumber)}
                              onClick={() => setSelectedOrderNumber(record.orderNumber)}>
                            <FormattedMessage id="main.delete"/>
                        </Link>
                    </Popconfirm>
                </>
            )
        }
    ];

    const hasProgressGreaterOrEqualOne = (orderNumber) => {
        const order = orderData.find(order => order.orderNumber === orderNumber);
        return order && order.orderDetails.some(item => item.progress >= 1);
    };

    const handleMenuClick = (e) => {
        switch (e.key) {
            case "dashboard":
                navigate('/dashboard');
                break;
            case "projects":
                navigate('/project');
                break;
            case "order":
                break;
            case "inventory":
                navigate('/inventory');
                break;
            case "accounting":
                warningMessage(intl.formatMessage({id: "main.underDeveloping"}))
                // navigate('/accounting');
                break;
            case "settings":
                navigate('/settings');
                break;
            default:
            // Handle other cases or do nothing
        }
    };

    const handleSearch = (value) => {
        setSearchValue(value);
        setSelectedProjectId(null);

        if (!value) {
            // If the search field is empty, reset to full orderData
            setFilteredOrderData(orderData);
            return;
        }

        // Filter orders based on client name containing the search value (case-insensitive)
        const filteredData = orderData.filter(order =>
            order.client && order.client.toLowerCase().includes(value.toLowerCase())
        );

        setFilteredOrderData(filteredData);
    };

    const handleSelect = (value, option) => {
        const selectedProjectId = option?.projectId;
        setSelectedProjectId(selectedProjectId);
    };


    const handleChangeType = (value) => {
        setSelectMaterialType(value);

        if (tableRef.current) {
            tableRef.current.scrollTo({
                index: 0
            });
        }
    };

    const handleSetOrderTableDetail = (orderNumber) => {
        const order = orderData.find(order => order.orderNumber === orderNumber);
        if (order) {
            setSelectedOrderDetails(order.orderDetails);
            setSelectedOrderNumber(orderNumber);
        }
    };

    const fetchProjectIdNames = () => {
        axios.post(
            "/api/fetch_project_names/",
            {
                parameters: {
                    userId: userInfo.userId,
                    authKey: userInfo.authKey,
                    os: userInfo.os,
                    datetime: new Date()
                },
                requestName: 'fetch_project_names',
                jobId: uuidv4()
            },
            {
                headers: {'X-CSRFToken': csrfToken},
                withCredentials: true
            }
        ).then(response => {
            if (response.data.status === "success") {
                const projectIdNames = response.data.projectIdNames;
                setProjectOptions(projectIdNames);
            } else {
                errorMessage("Failed to fetch projects:\n", response.data.responseData.errorMessage);
                console.error("Failed to fetch projects:\n", response.data.responseData.errorMessage);
            }
        }).catch(error => {
            errorMessage("Error fetching projects:", error);
            console.error("Error fetching projects:", error);
        });
    };

    const fetchOrderData = () => {
        setIsFetchingData(true);
        axios.post(
            "/api/fetch_all_orders/",
            {
                parameters: {
                    userId: userInfo.userId,
                    os: userInfo.os,
                    authKey: userInfo.authKey,
                    datetime: new Date()
                },
                requestName: 'fetch_all_orders',
                jobId: uuidv4()
            },
            {
                headers: {'X-CSRFToken': csrfToken},
                withCredentials: true
            }
        ).then(response => {
            setIsFetchingData(false);
            if (response.data.status === "success") {
                const orderInfo = response.data.responseData;
                setOrderData(orderInfo);
                setFilteredOrderData(orderInfo);
            } else {
                errorMessage("Failed to fetch projects:\n", response.data.responseData.errorMessage);
                console.error("Failed to fetch projects:\n", response.data.responseData.errorMessage);
            }
        }).catch(error => {
            setIsFetchingData(false);
            errorMessage("Error fetching projects:", error);
            console.error("Error fetching projects:", error);
        });
    };

    const fetchInventoryData = () => {
        setIsFetchingData(true);
        axios.post(
            "/api/fetch_inventory/",
            {
                parameters: {
                    userId: userInfo.userId,
                    os: userInfo.os,
                    authKey: userInfo.authKey,
                    datetime: new Date()
                },
                requestName: 'fetch_inventory',
                jobId: uuidv4()
            },
            {
                headers: {'X-CSRFToken': csrfToken},
                withCredentials: true
            }
        ).then(response => {
            if (response.data.status === "success") {
                const inventoryData = response.data.responseData;
                setInventoryData(inventoryData);
                setIsFetchingData(false);
            } else {
                console.error("Failed to fetch projects:\n", response.data.responseData.errorMessage);
            }
        }).catch(error => {
            console.error("Error fetching projects:", error);
        });
    };

    const handleOrderSubmit = (orderNumber, orderDetails) => {
        setIsSubmittingOrder(true);
        axios.post(
            "/api/submit_order/",
            {
                parameters: {
                    userId: userInfo.userId,
                    os: userInfo.os,
                    projectId: selectedProjectId || searchValue,
                    datetime: new Date(),
                    orderNumber: orderNumber,
                    authKey: userInfo.authKey,
                    orderDetails: orderDetails
                },
                requestName: 'submit_order',
                jobId: uuidv4()
            },
            {
                headers: {'X-CSRFToken': csrfToken},
                withCredentials: true
            }
        ).then(response => {
            setIsSubmittingOrder(false);
            if (response.data.status === "success") {
                setShowSubmitOrderModal(false);
                setTimeout(() => {
                    const newOrder = response.data.newOrder;
                    if (newOrder) {
                        // Update the state arrays with the new order
                        setOrderData(prevOrders => [newOrder, ...prevOrders]);
                        setFilteredOrderData(prevOrders => [newOrder, ...prevOrders]);
                    }

                    fetchProjectIdNames();
                    handleChangeType('Elevator Parts');
                    successMessage(intl.formatMessage({id: "projectPage.submitOrder.success"}, {orderNumber: orderNumber}));
                }, 300);
            } else {
                errorMessage("Failed to submit order:\n", response.data.responseData.errorMessage);
                console.error("Failed to submit order:\n", response.data.responseData.errorMessage);
            }
        }).catch(error => {
            setIsSubmittingOrder(false);
            errorMessage("Error submitting order:", error);
            console.error("Error submitting order:", error);
        });
    };

    const handleOrderUpdate = (orderNumber, newOrderDetail) => {
        setIsUpdatingOrder(true);

        axios.post(
            "/api/update_order/",
            {
                parameters: {
                    userId: userInfo.userId,
                    os: userInfo.os,
                    projectId: selectedProjectId,
                    orderNumber: orderNumber,
                    authKey: userInfo.authKey,
                    datetime: new Date(),
                    orderDetails: newOrderDetail
                },
                requestName: 'update_order',
                jobId: uuidv4()
            },
            {
                headers: {'X-CSRFToken': csrfToken},
                withCredentials: true
            }
        ).then(response => {
            setIsUpdatingOrder(false);
            setSelectedProjectId(null);
            if (response.data.status === "success") {
                const orderCompleted = response.data.responseData.orderCompleted;
                setOrderData(prevOrderData =>
                    prevOrderData.map(order =>
                        order.orderNumber === selectedOrderNumber ? {...order, complete: orderCompleted, orderDetails: newOrderDetail} : order
                    )
                );
                setFilteredOrderData(prevOrderData =>
                    prevOrderData.map(order =>
                        order.orderNumber === selectedOrderNumber ? {...order, complete: orderCompleted, orderDetails: newOrderDetail} : order
                    )
                );
                setShowOrderTableEditModal(false);
                setTimeout(() => {
                    successMessage(intl.formatMessage({id: "projectPage.updateOrder.success"}, {orderNumber: selectedOrderNumber}));
                }, 300);
            } else {
                errorMessage("Failed to update order: " + response.data.responseData.errorMessage);
                console.error("Failed to update order: ", response.data.responseData.errorMessage);
            }
        }).catch(error => {
            setIsUpdatingOrder(false);
            errorMessage("Error updating order: " + error);
            console.error("Error updating order: ", error);
        });
    };

    const handleOrderDelete = () => {
        setIsDeletingOrder(true);

        axios.post(
            "/api/delete_order/",
            {
                parameters: {
                    userId: userInfo.userId,
                    os: userInfo.os,
                    orderNumber: selectedOrderNumber,
                    authKey: userInfo.authKey,
                    datetime: new Date(),
                },
                requestName: 'delete_order',
                jobId: uuidv4()
            },
            {
                headers: {'X-CSRFToken': csrfToken},
                withCredentials: true
            }
        ).then(response => {
            setIsDeletingOrder(false);
            if (response.data.status === "success") {
                setOrderData(prevOrderData => prevOrderData.filter(order => order.orderNumber !== selectedOrderNumber));
                setFilteredOrderData(prevOrderData => prevOrderData.filter(order => order.orderNumber !== selectedOrderNumber));
                successMessage(intl.formatMessage({id: "projectPage.deleteOrder.success"}, {orderNumber: selectedOrderNumber}));
            } else {
                errorMessage("Failed to delete order:\n", response.data.responseData.errorMessage);
                console.error("Failed to delete order:\n", response.data.responseData.errorMessage);
            }
        }).catch(error => {
            setIsUpdatingOrder(false);
            errorMessage("Error deleting order:", error);
            console.error("Error deleting order:", error);
        });
    };

    const handleOrderExport = (tableType, orderNumber, selectedData) => {
        axios.post(
            "/api/export_order/",
            {
                parameters: {
                    userId: userInfo.userId,
                    os: userInfo.os,
                    orderNumber: orderNumber,
                    orderDetail: selectedData,
                    tableType: tableType,
                    authKey: userInfo.authKey,
                    datetime: new Date(),
                },
                requestName: 'export_order',
                jobId: uuidv4()
            },
            {
                headers: {'X-CSRFToken': csrfToken},
                responseType: 'blob',
                withCredentials: true
            }
        ).then(response => {
            if (response.status === 200) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `order_${selectedOrderNumber}.xlsx`);
                document.body.appendChild(link);
                link.click();
            } else {
                errorMessage("Failed to export order.");
                console.error("Failed to export order.");
            }
        }).catch(error => {
            setIsUpdatingOrder(false);
            errorMessage("Error exporting order: " + error);
            console.error("Error exporting order: ", error);
        });
    };

    const handleLogout = () => {
        setCookie('userInfo', '', {path: '/', expires: new Date(0)}); // Setting a past date to delete the cookie
        navigate('/login');
    };

    const successMessage = (message) => {
        messageApi.open({
            type: 'success',
            content: message,
        });
    };

    const warningMessage = (message) => {
        messageApi.open({
            type: 'warning',
            content: message,
        });
    };

    const errorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };


    return (
        <Layout style={{minHeight: '100vh'}}>
            {contextHolder}
            <Sider theme="light" style={{position: 'relative', height: '100vh'}} width={240}>
                <div className="sider-header" style={{padding: '16px', background: '#fff'}}>
                    <FormattedMessage id="sider.title" values={{versionNumber: userInfo.versionNumber}}/>
                </div>
                <Menu theme="light"
                      style={{borderRight: 0}}
                      items={siderMenuItems}
                      defaultSelectedKeys={['order']}
                      mode="inline"
                      onClick={handleMenuClick}
                />
                <div style={{position: 'absolute', bottom: 0, width: '100%', padding: '16px', borderTop: '1px solid #f0f0f0'}}>
                    <Link onClick={handleLogout} style={{display: 'flex', alignItems: 'center'}}>
                        <LogoutOutlined/>
                        <span style={{marginLeft: '8px'}}>
                            <FormattedMessage id="sider.logout"/>
                        </span>
                    </Link>
                </div>
            </Sider>
            <Layout className="site-layout" style={{minHeight: '100vh', backgroundColor: '#f0f2f5'}}>
                <Content style={{margin: '0 16px', backgroundColor: '#f0f2f5'}}>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Title level={2}><FormattedMessage id="orderPage.title"/></Title>
                        <DropDownMenuLanguage setLocale={setLocale}/>
                    </div>
                    <Space direction="vertical" style={{width: '100%'}} gap="large">
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <Flex wrap="wrap" gap="middle">
                                <AutoComplete style={{width: '50vh'}}
                                              placeholder={intl.formatMessage({id: "orderPage.typeToSearch"})}
                                              suffixIcon={<SearchOutlined/>}
                                              options={projectOptions}
                                              onSearch={handleSearch}
                                              value={searchValue}
                                              onChange={handleSearch}
                                              onSelect={handleSelect}
                                              allowClear
                                              filterOption={(inputValue, option) =>
                                                  option.value.toLowerCase().includes(inputValue.toLowerCase())
                                              }
                                />
                                <Button icon={<ShoppingCartOutlined/>}
                                        disabled={searchValue === ''}
                                        onClick={() => {
                                            setOrderNumber(uuidv4().split('-').pop());
                                            setShowSubmitOrderModal(true);
                                            fetchInventoryData();

                                            // Handle the case for un-exist client
                                            if (!selectedProjectId) {
                                                setSelectedProjectId(searchValue);
                                            }
                                        }}
                                >
                                    <span style={{paddingLeft: '0px'}}><FormattedMessage id="orderPage.createOrder"/></span>
                                </Button>
                            </Flex>
                        </div>
                        <Table dataSource={filteredOrderData}
                               columns={orderDetailColumns}
                               loading={isFetchingData}
                               pagination={false}
                               scroll={{y: '79vh'}}
                               size="middle"
                        />
                    </Space>
                </Content>
                <OrderMaterialModal title={intl.formatMessage({id: "orderPage.submitOrder"}, {client: searchValue})}
                                    client={searchValue}
                                    orderNumber={orderNumber}
                                    projectNumber={selectedProjectId}
                                    visible={showSubmitOrderModal}
                                    onClose={() => setShowSubmitOrderModal(false)}
                                    onSubmit={handleOrderSubmit}
                                    isSubmittingOrder={isSubmittingOrder}
                                    inventoryData={inventoryData}
                                    allOrderData={orderData}
                                    tableHeight={tableHeight}
                />
                <EditOrderDetailModal orderNumber={selectedOrderNumber}
                                      orderDetails={selectedOrderDetails}
                                      visible={showOrderTableEditModal}
                                      onClose={() => setShowOrderTableEditModal(false)}
                                      onSubmit={handleOrderUpdate}
                                      onExport={handleOrderExport}
                                      isUpdatingOrder={isUpdatingOrder}
                />
            </Layout>
        </Layout>
    );
};

export default OrderPage;
