import {Button, Checkbox, Modal, Typography} from "antd";
import React, {useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import axios from "axios";

import '../projectPage.css';

const {Link, Text} = Typography;

const UpdateLogModal = ({visible, onClose, version, onStopShowAgain}) => {
    const [updateLog, setUpdateLog] = useState({version: "", logs: []});
    const [dontShowAgain, setDontShowAgain] = useState(false);


    const updateLogTitle = (
        <h3 style={{fontSize: '20px', margin: 0}}>版本更新日志 {version}</h3>
    );


    useEffect(() => {
        // Fetch the update log content
        axios.get(`updateLog_${version}.json`)
            .then(response => {
                setUpdateLog(response.data);
            })
            .catch(error => {
                console.error("Failed to fetch update log:", error);
            });
    }, []);

    const updateLogContent = (
        <div>
            {updateLog.logs.map((log, index) => (
                <div key={index}>
                    <h4 style={{fontSize: '15px', marginBottom: '10px'}}>{log.title}</h4>
                    <ul>
                        {log.items.map((item, idx) => (
                            <li key={idx}>{item}</li>
                        ))}
                    </ul>
                </div>
            ))}
            <div style={{marginTop: '20px'}}>
                <Checkbox checked={dontShowAgain}
                          onChange={(e) => setDontShowAgain(e.target.checked)}
                >
                    <FormattedMessage id="main.dontShowAgain"/>
                </Checkbox>
            </div>
        </div>
    );


    return (
        <Modal title={updateLogTitle}
               open={visible}
               onCancel={onClose}
               width="550px"
               footer={
                   <Button type="primary" onClick={() => onStopShowAgain(dontShowAgain)}>
                       <FormattedMessage id="main.ok"/>
                   </Button>
               }
        >
            {updateLogContent}
        </Modal>
    );
};

export default UpdateLogModal;
