import React from 'react';
import '../projectPage.css'

const Container = ({id, children}) => {

    return (
        <div className="container-board"
             style={{
                 maxHeight: '80vh',
                 overflowY: 'auto'
             }}
        >
            {children}
        </div>
    );
};

export default Container;
