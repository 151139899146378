import {AutoComplete, Button, DatePicker, Flex, Input, InputNumber, message, Modal, Space, Table} from "antd";
import React, {useEffect, useRef, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import Draggable from "react-draggable";

import dayjs from "dayjs";
import isEqual from "lodash/isEqual";

import '../projectPage.css';
import {CheckOutlined, DeleteOutlined, ExportOutlined} from "@ant-design/icons";

const {TextArea} = Input;


const vendorOptions = [
    {value: 'KINGS ELEVATOR PARTS INC.', label: 'KINGS ELEVATOR PARTS INC.'},
    {value: 'ZZIPCO', label: 'ZZIPCO'},
    {value: 'FLAME CUT STEEL INC.', label: 'FLAME CUT STEEL INC.'},
    {value: 'MEGA ELEVATOR PARTS', label: 'MEGA ELEVATOR PARTS'},
    {value: 'XPRESS DOOR-N-CABS', label: 'XPRESS DOOR-N-CABS'},
    {value: 'TANNER BOLT N NUT INC.', label: 'TANNER BOLT N NUT INC.'},
    {value: 'HOLLISTER WHITNEY', label: 'HOLLISTER WHITNEY'},
    {value: 'GAL', label: 'GAL'},
    {value: 'ESI', label: 'ESI'},
    {value: 'PELLE', label: 'PELLE'},
    {value: 'THE HOME DEPOT', label: 'THE HOME DEPOT'},
    {value: 'Amazon', label: 'Amazon'},
    {value: 'VANTAGE ELEVATION', label: 'VANTAGE ELEVATION'},
    {value: 'Smartrise Engineering', label: 'Smartrise Engineering'},
    {value: 'Canton Elevator', label: 'Canton Elevator'},
    {value: 'Kwong Ming Lighting n Furnature Inc.', label: 'Kwong Ming Lighting n Furnature Inc.'},
    {value: 'Lendy Electric', label: 'Lendy Electric'},
    {value: 'Elevator Products Corp', label: 'Elevator Products Corp'},
    {value: 'DBA JANUS ELEVATOR PRODUCTS', label: 'DBA JANUS ELEVATOR PRODUCTS'},
    {value: 'Giant Lift', label: 'Giant Lift'},
    {value: 'PFLOW INDUSTRIES', label: 'PFLOW INDUSTRIES'},
    {value: 'AUTOZONE', label: 'AUTOZONE'},
    {value: 'DIGIKEY', label: 'DIGIKEY'},
    {value: 'COLLEGE POINT ELEC SUPPLIES', label: 'COLLEGE POINT ELEC SUPPLIES'},
    {value: 'ZORO', label: 'ZORO'},
    {value: 'AIRWELD', label: 'AIRWELD'},
    {value: 'The Peelle Company Ltd.', label: 'The Peelle Company Ltd.'},
    {value: 'LOWE', label: 'LOWE'},
    {value: 'Savaria USA Inc', label: 'Savaria USA Inc'},
    {value: 'GRAINGER', label: 'GRAINGER'},
    {value: 'Certified elevator Escalator Products', label: 'Certified elevator Escalator Products'},
    {value: 'QB11 ELECTRICAL SUPPLY INC.', label: 'QB11 ELECTRICAL SUPPLY INC.'},
    {value: 'Royal Metal Products USA Corp.', label: 'Royal Metal Products USA Corp.'},
];


const EditOrderDetailModal = ({orderNumber, orderDetails, visible, onClose, onSubmit, onExport, isUpdatingOrder}) => {
    const intl = useIntl();
    const [messageApi, contextHolder] = message.useMessage();

    // Draggable modal
    const dragNodeRef = useRef(null);
    const [modalPosition, setModalPosition] = useState({x: 0, y: 0});
    const [dragDisabled, setDragDisabled] = useState(true);

    // Table
    const [selectedOrderDetails, setSelectedOrderDetails] = useState(orderDetails);
    const [filteredVendorOptions, setFilteredVendorOptions] = useState(vendorOptions);

    // Row selections
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [isDeleteDisabled, setIsDeleteDisabled] = useState(true);
    const [isExportDisabled, setIsExportDisabled] = useState(true);

    // Ensures that the modal's internal state (selectedOrderDetails) is
    // synchronized with the latest orderDetails prop passed from the parent component
    useEffect(() => {
        setSelectedOrderDetails(orderDetails.map(item => ({...item})));
    }, [orderDetails]);


    const orderDetailTableColumns = [
        {title: <FormattedMessage id="inventoryPage.productCode"/>, dataIndex: 'itemId', key: 'itemId', width: 80, fixed: 'left'},
        {title: <FormattedMessage id="inventoryPage.productName"/>, dataIndex: 'productName', key: 'productName', width: 240, fixed: 'left'},
        {title: <FormattedMessage id="inventoryPage.specification"/>, dataIndex: 'specification', key: 'specification', width: 250},
        {
            title: <FormattedMessage id="inventoryPage.costPrice"/>,
            dataIndex: 'costPrice',
            key: 'costPrice',
            width: 120,
            render: (text, record) => (
                <InputNumber min={0}
                             value={record.costPrice}
                             onChange={(value) => handleEditOrderFieldChange(record.itemId, 'costPrice', value)}
                             style={{width: '100%'}}
                />
            ),
        },
        {
            title: <FormattedMessage id="inventoryPage.retailPrice"/>,
            dataIndex: 'retailPrice',
            key: 'retailPrice',
            width: 120,
            render: (text, record) => (
                <InputNumber min={0}
                             value={record.retailPrice}
                             onChange={(value) => handleEditOrderFieldChange(record.itemId, 'retailPrice', value)}
                             style={{width: '100%'}}
                />
            ),
        },
        {
            title: <FormattedMessage id="materialTable.quantity"/>,
            dataIndex: 'orderQuantity',
            key: 'orderQuantity',
            width: 120,
            render: (text, record) => (
                <InputNumber min={1}
                             value={record.orderQuantity}
                             onChange={(value) => handleEditOrderFieldChange(record.itemId, 'orderQuantity', value)}
                             style={{width: '100%'}}
                />
            ),
        },
        {
            title: <FormattedMessage id="inventoryPage.price"/>,
            dataIndex: 'totalPrice',
            key: 'totalPrice',
            width: 120,
            render: (text, record) => (record.orderQuantity * record.retailPrice).toFixed(2),
        },
        {
            title: <FormattedMessage id="materialTable.progress"/>,
            dataIndex: 'progress',
            key: 'progress',
            width: 120,
            render: (text, record) => (
                <InputNumber min={0}
                             max={record.orderQuantity}
                             value={record.progress}
                             onChange={(value) => handleEditOrderFieldChange(record.itemId, 'progress', value)}
                             style={{width: '100%'}}
                />
            ),
        },
        {title: <FormattedMessage id="inventoryPage.unit"/>, dataIndex: 'unit', key: 'unit', width: 60},
        {
            title: <FormattedMessage id="inventoryPage.vendor"/>,
            dataIndex: 'vendor',
            key: 'vendor',
            width: 200,
            render: (text, record) => (
                <AutoComplete options={filteredVendorOptions}
                              value={record.vendor}
                              onSearch={handleVendorSearch}
                              style={{width: '100%'}}
                              placeholder={intl.formatMessage({id: "inventoryPage.selectVendor"})}
                              onChange={(value) => handleEditOrderFieldChange(record.itemId, 'vendor', value)}
                />
            )
        },
        {
            title: <FormattedMessage id="inventoryPage.memo"/>,
            dataIndex: 'memo',
            key: 'memo',
            width: 150,
            render: (text, record) => (
                <TextArea value={record.memo}
                          onChange={(e) => handleEditOrderFieldChange(record.itemId, 'memo', e.target.value)}
                          placeholder={intl.formatMessage({id: "inventoryPage.enterMemo"})}
                          autoSize
                />
            )
        },
        {
            title: <FormattedMessage id="inventoryPage.arrivalDate"/>,
            dataIndex: 'arrivalDate',
            key: 'arrivalDate',
            width: 150,
            render: (text, record) => (
                <DatePicker value={record.arrivalDate ? dayjs(record.arrivalDate) : null}
                            onChange={(date) => handleEditOrderFieldChange(record.itemId, 'arrivalDate', date ? date.startOf('day').format('YYYY-MM-DD') : null)}
                />
            )
        }
    ];

    const handleEditOrderFieldChange = (itemId, field, value) => {
        setSelectedOrderDetails((prevDetails) =>
            prevDetails.map((item) => {
                if (item.itemId === itemId) {
                    const updatedItem = {...item, [field]: value};
                    if (field === 'orderQuantity' || field === 'retailPrice') {
                        updatedItem.totalPrice = (updatedItem.orderQuantity || 0) * (updatedItem.retailPrice || 0);
                    }
                    return updatedItem;
                }
                return item;
            })
        );
    };

    const handleVendorSearch = (value) => {
        const filteredOptions = vendorOptions.filter(option =>
            option.label.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredVendorOptions(filteredOptions);
    };

    const handleSubmit = () => {
        if (isEqual(selectedOrderDetails, orderDetails)) {
            onClose();
            setTimeout(() => {
                setSelectedOrderDetails(orderDetails);
                setSelectedRows([]);
                setSelectedRowKeys([]);
                setModalPosition({x: 0, y: 0});
            }, 300);
            successMessage(intl.formatMessage({id: "projectPage.submitOrder.success"}, {orderNumber: orderNumber}));
            return;
        }

        onSubmit(orderNumber, selectedOrderDetails);
    };

    const handleExport = () => {
        let selectedData;

        selectedData = selectedOrderDetails
            .filter(item => selectedRowKeys.includes(item.key))
            .map(item => ({
                ...item,
                totalPrice: (item.orderQuantity || 0) * (item.retailPrice || 0)
            }));

        onExport("edit", orderNumber, selectedData);
    }

    const onSelectChange = (newSelectedRowKeys, newSelectedRows) => {
        const hasProgressGreaterThanOne = newSelectedRowKeys.some(key => {
            const selectedRow = selectedOrderDetails.find(item => item.itemId === key);
            return selectedRow && selectedRow.progress > 0;
        });

        const allRowsSelected = selectedOrderDetails.length === newSelectedRowKeys.length;

        setSelectedRowKeys(newSelectedRowKeys);
        setSelectedRows(newSelectedRows);
        setIsDeleteDisabled(newSelectedRows.length === 0 || hasProgressGreaterThanOne || allRowsSelected);
        setIsExportDisabled(newSelectedRows.length === 0);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: 50,
    };

    const arriveAll = () => {
        setSelectedOrderDetails((prevDetails) =>
            prevDetails.map((item) => ({
                ...item,
                progress: item.orderQuantity,
            }))
        );
    };

    const arriveAllSelected = () => {
        setSelectedOrderDetails((prevDetails) =>
            prevDetails.map((item) =>
                selectedRowKeys.includes(item.itemId)
                    ? {...item, progress: item.orderQuantity}
                    : item
            )
        );
    };

    const deleteSelected = () => {
        setSelectedOrderDetails(prevDetails =>
            prevDetails.filter(item => !selectedRowKeys.includes(item.itemId))
        );
        setSelectedRowKeys([]); // Clear the selection
    };

    const getTotalSelectedPrice = () => {
        const total = selectedRowKeys.reduce((total, key) => {
            const item = selectedOrderDetails.find((detail) => detail.itemId === key);
            return total + (item.orderQuantity * item.retailPrice);
        }, 0);

        return new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(total);
    };


    const successMessage = (message) => {
        messageApi.open({
            type: 'success',
            content: message,
        });
    };

    const warningMessage = (message) => {
        messageApi.open({
            type: 'warning',
            content: message,
        });
    };

    const errorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };


    return (
        <div>
            {contextHolder}
            <Modal title={
                <div style={{width: '100%', cursor: 'move'}}
                     onMouseOver={() => setDragDisabled(false)}
                     onMouseOut={() => setDragDisabled(true)}
                >
                    {intl.formatMessage({id: "materialTable.editOrderNumber"}, {orderNumber: orderNumber})}
                </div>
            }
                   centered
                   width="80%"
                   open={visible}
                   onCancel={() => {
                       onClose();
                       setTimeout(() => {
                           setSelectedOrderDetails(orderDetails);
                           setSelectedRows([]);
                           setSelectedRowKeys([]);
                           setModalPosition({x: 0, y: 0});
                       }, 300);
                   }}
                   maskClosable={false}
                   onOk={handleSubmit}
                   okText={intl.formatMessage({id: "main.save"})}
                   okButtonProps={{loading: isUpdatingOrder}}
                   modalRender={(modal) => (
                       <Draggable disabled={dragDisabled}
                                  nodeRef={dragNodeRef}
                                  position={modalPosition}
                                  onStop={(e, data) => {
                                      setModalPosition({x: data.x, y: data.y});
                                  }}
                       >
                           <div ref={dragNodeRef}>{modal}</div>
                       </Draggable>
                   )}
            >
                <div style={{maxHeight: '70vh', height: '70vh'}}>
                    <Space direction="vertical" style={{width: '100%'}} gap="large">
                        <Flex wrap="wrap" gap="middle" style={{width: '100%'}}>
                            <Button disabled={selectedRows.length > 0}
                                    icon={<CheckOutlined/>}
                                    style={{width: '120px'}}
                                    onClick={arriveAll}
                            >
                                <FormattedMessage id="materialTable.complete"/>
                            </Button>
                            <Button disabled={selectedRows.length < 1}
                                    icon={<CheckOutlined/>}
                                    style={{width: '120px'}}
                                    onClick={arriveAllSelected}
                            >
                                <FormattedMessage id="materialTable.selectedComplete"/>
                            </Button>
                            <Button icon={<ExportOutlined/>}
                                    style={{width: '120px'}}
                                    disabled={isExportDisabled}
                                    onClick={handleExport}
                            >
                                <span style={{paddingLeft: '0px'}}><FormattedMessage id="main.export"/></span>
                            </Button>
                            <Button icon={<DeleteOutlined/>}
                                    danger
                                    style={{width: '120px'}}
                                    disabled={isDeleteDisabled}
                                    onClick={deleteSelected}
                            >
                                <span style={{paddingLeft: '0px'}}><FormattedMessage id="main.delete"/></span>
                            </Button>
                        </Flex>
                        <Table columns={orderDetailTableColumns}
                               dataSource={selectedOrderDetails}
                               rowSelection={rowSelection}
                               pagination={false}
                               virtual={true}
                               scroll={{y: '60vh'}}
                               size="middle"
                               footer={() => (
                                   <div style={{textAlign: 'left', paddingRight: '20px'}}>
                                       {intl.formatMessage({id: "inventoryPage.totalPrice"}, {totalPrice: getTotalSelectedPrice()})}
                                   </div>
                               )}
                        />
                    </Space>
                </div>
            </Modal>
        </div>
    );
};

export default EditOrderDetailModal;
