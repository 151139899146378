import {Input, message, Modal, Table} from "antd";
import React, {useEffect, useRef, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import '../projectPage.css';

const {TextArea} = Input;


const OrderHistoryModal = ({title, visible, orderHistory, onClose, onAddHistoryMaterial, tableHeight}) => {
    const intl = useIntl();
    const [messageApi, contextHolder] = message.useMessage();
    const materialTableRef = useRef(null);

    const [selectedRowsData, setSelectedRowsData] = useState([]);


    // Table columns for order history
    const columns = [
        {title: <FormattedMessage id="inventoryPage.productCode"/>, dataIndex: 'itemId', key: 'itemId', width: 80, fixed: 'left',},
        {title: <FormattedMessage id="inventoryPage.productName"/>, dataIndex: 'productName', key: 'productName', width: 250, fixed: 'left',},
        {title: <FormattedMessage id="inventoryPage.specification"/>, dataIndex: 'specification', key: 'specification', width: 250},
        {
            title: <FormattedMessage id="inventoryPage.costPrice"/>,
            dataIndex: 'costPrice',
            key: 'costPrice',
            width: 100,
        },
        {
            title: <FormattedMessage id="inventoryPage.retailPrice"/>,
            dataIndex: 'retailPrice',
            key: 'retailPrice',
            width: 100,
        },
        {title: <FormattedMessage id="inventoryPage.unit"/>, dataIndex: 'unit', key: 'unit', width: 80},
        {
            title: <FormattedMessage id="inventoryPage.vendor"/>,
            dataIndex: 'vendor',
            key: 'vendor',
            width: 180,
        },
        {
            title: <FormattedMessage id="inventoryPage.memo"/>,
            dataIndex: 'memo',
            key: 'memo',
            width: 180,
        }
    ];

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            const mappedRows = selectedRows.map(row => ({
                key: row.key !== undefined ? row.key : null,
                costPrice: row.costPrice !== undefined ? row.costPrice : null,
                retailPrice: row.retailPrice !== undefined ? row.retailPrice : null,
                vendor: row.vendor !== undefined ? row.vendor : null,
                memo: row.memo !== undefined ? row.memo : null,
            }));
            setSelectedRowsData(mappedRows);
        },
        columnWidth: 50,
    };

    useEffect(() => {
        if (!visible) {
            setSelectedRowsData([]);
        } else {
        }
    }, [visible]);

    const successMessage = (message) => {
        messageApi.open({
            type: 'success',
            content: message,
        });
    };

    const warningMessage = (message) => {
        messageApi.open({
            type: 'warning',
            content: message,
        });
    };

    const errorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };


    return (
        <div>{contextHolder}
            <Modal title={title}
                   open={visible}
                   onCancel={onClose}
                   okText={intl.formatMessage({id: "orderPage.addToOrder"}, {amount: selectedRowsData.length})}
                   okButtonProps={{disabled: selectedRowsData.length < 1}}
                   onOk={() => onAddHistoryMaterial(selectedRowsData)}
                   centered
                   width="70%"
                   destroyOnClose
                   maskClosable={false}
            >
                <div style={{maxHeight: '70vh', height: '70vh'}}>
                    <div style={{maxHeight: tableHeight, overflowY: 'auto'}}>
                        <Table ref={materialTableRef}
                               columns={columns}
                               dataSource={orderHistory}
                               rowKey="itemId"
                               rowSelection={rowSelection}
                               virtual
                               pagination={false}
                               scroll={{x: 1300, y: tableHeight}}
                               size="middle"
                        />
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default OrderHistoryModal;
