import React, {useEffect, useRef, useState} from 'react';
import {Button, Flex, Input, Layout, Menu, message, Space, Table, Tag, Typography} from 'antd';
import {
    AccountBookOutlined,
    CreditCardOutlined,
    FundProjectionScreenOutlined,
    LogoutOutlined,
    PieChartOutlined,
    SettingOutlined,
    ShopOutlined,
    ToolOutlined,
} from '@ant-design/icons';
import {useNavigate} from 'react-router-dom';
import {FormattedMessage, useIntl} from "react-intl";
import DropDownMenuLanguage from "./components/DropDownMenuLanguage";
import axios from "axios";
import dayjs from 'dayjs';
import {v4 as uuidv4} from "uuid";
import commandIcon from "./icons/icons8-command-96.png";
import ctrlIcon from "./icons/icons8-ctrl-96.png";
import fIcon from "./icons/icons8-f-key-96.png";
import {useCookies} from "react-cookie";

dayjs.extend(require('dayjs/plugin/utc'))
dayjs.extend(require('dayjs/plugin/timezone'))

const {Header, Content, Footer, Sider} = Layout;
const {SubMenu} = Menu;
const {Title, Link} = Typography;
const {TextArea} = Input;

function getSiderMenuItem(label, key, icon, children, type) {
    return {key, icon, children, label, type};
}

const siderMenuItems = [
    getSiderMenuItem(<FormattedMessage id="sider.dashboard"/>, 'dashboard', <PieChartOutlined/>),
    getSiderMenuItem(<FormattedMessage id="sider.projects"/>, 'projects', <FundProjectionScreenOutlined/>),
    getSiderMenuItem(<FormattedMessage id="sider.order"/>, 'order', <CreditCardOutlined/>),
    getSiderMenuItem(<FormattedMessage id="sider.inventory"/>, 'inventory', <ShopOutlined/>),
    getSiderMenuItem(<FormattedMessage id="sider.accounting"/>, 'accounting', <AccountBookOutlined/>),
    getSiderMenuItem(<FormattedMessage id="sider.settings"/>, 'settings', <SettingOutlined/>),
];


const InventoryPage = ({setLocale}) => {
    const navigate = useNavigate();
    const intl = useIntl(); // Hook to get the intl object
    const tableRef = useRef(null);
    const orderTableRef = useRef(null);
    const [messageApi, contextHolder] = message.useMessage();
    const [cookies, setCookie] = useCookies(['userInfo']);
    const [userInfo, setUserInfo] = useState(cookies.userInfo || {});

    const [csrfToken, setCsrfToken] = useState(null);
    const [inventoryData, setInventoryData] = useState([]);
    const [selectMaterialType, setSelectMaterialType] = useState('Elevator Parts');
    const [isFetchingData, setIsFetchingData] = useState(false);
    const [tableHeight, setTableHeight] = useState(500);


    const updateTableHeight = () => {
        const parentHeight = orderTableRef.current?.parentElement?.offsetHeight || window.innerHeight;
        const newTableHeight = parentHeight * 0.57;
        setTableHeight(newTableHeight);
    };

    useEffect(() => {
        updateTableHeight();
        window.addEventListener('resize', updateTableHeight);

        return () => {
            window.removeEventListener('resize', updateTableHeight);
        };
    }, []);


    const materialTypes = {
        'Elevator Parts': <FormattedMessage id="materialTable.elevatorParts"/>,
        'Materials': <FormattedMessage id="materialTable.materials"/>,
        'Consumables': <FormattedMessage id="materialTable.consumables"/>,
        'Tools': <FormattedMessage id="materialTable.tools"/>,
        'Others': <FormattedMessage id="materialTable.others"/>,
    };

    const columns = [
        {title: <FormattedMessage id="inventoryPage.productCode"/>, dataIndex: 'productCode', key: 'productCode', width: 80, fixed: 'left',},
        {title: <FormattedMessage id="inventoryPage.productName"/>, dataIndex: 'productName', key: 'productName', width: 250, fixed: 'left',},
        {title: <FormattedMessage id="inventoryPage.specification"/>, dataIndex: 'specification', key: 'specification', width: 250},
        {title: <FormattedMessage id="inventoryPage.costPrice"/>, dataIndex: 'costPrice', key: 'costPrice', width: 80},
        {title: <FormattedMessage id="inventoryPage.retailPrice"/>, dataIndex: 'retailPrice', key: 'retailPrice', width: 80},
        {title: <FormattedMessage id="inventoryPage.stockLowerLimit"/>, dataIndex: 'stockLowerLimit', key: 'stockLowerLimit', width: 100},
        {title: <FormattedMessage id="inventoryPage.stockUpperLimit"/>, dataIndex: 'stockUpperLimit', key: 'stockUpperLimit', width: 100},
        {title: <FormattedMessage id="inventoryPage.inStockQuantity"/>, dataIndex: 'inStockQuantity', key: 'inStockQuantity', width: 100},
        {title: <FormattedMessage id="inventoryPage.unit"/>, dataIndex: 'unit', key: 'unit', width: 80}
    ];

    const searchMethod = userInfo.os === 'MacOS' ? (
        <>
            <img src={commandIcon} alt="Command" style={{width: '20px', verticalAlign: 'middle'}}/>
            +<img src={fIcon} alt="F key" style={{width: '20px', verticalAlign: 'middle'}}/>
        </>
    ) : (
        <>
            <img src={ctrlIcon} alt="Ctrl" style={{width: '20px', verticalAlign: 'middle'}}/>
            +<img src={fIcon} alt="F key" style={{width: '20px', verticalAlign: 'middle'}}/>
        </>
    );


    useEffect(() => {
        fetchInventoryData();
    }, []);


    const handleMenuClick = (e) => {
        switch (e.key) {
            case "dashboard":
                navigate('/dashboard');
                break;
            case "projects":
                navigate('/project');
                break;
            case "order":
                navigate('/order');
                break;
            case "inventory":
                break;
            case "accounting":
                warningMessage(intl.formatMessage({id: "main.underDeveloping"}))
                // navigate('/accounting');
                break;
            case "settings":
                navigate('/settings');
                break;
            default:
            // Handle other cases or do nothing
        }
    };

    const handleChangeType = (value) => {
        setSelectMaterialType(value);

        if (tableRef.current) {
            tableRef.current.scrollTo({
                index: 0
            });
        }
    };

    const fetchInventoryData = () => {
        setIsFetchingData(true);
        axios.post(
            "/api/fetch_inventory/",
            {
                parameters: {
                    userId: userInfo.userId,
                    os: userInfo.os,
                    authKey: userInfo.authKey,
                    datetime: new Date()
                },
                requestName: 'fetch_inventory',
                jobId: uuidv4()
            },
            {
                headers: {'X-CSRFToken': csrfToken},
                withCredentials: true
            }
        ).then(response => {
            if (response.data.status === "success") {
                const inventoryData = response.data.responseData;
                setInventoryData(inventoryData);
                setIsFetchingData(false);
            } else {
                console.error("Failed to fetch projects:\n", response.data.responseData.errorMessage);
            }
        }).catch(error => {
            console.error("Error fetching projects:", error);
        });
    };

    const handleLogout = () => {
        setCookie('userInfo', '', {path: '/', expires: new Date(0)}); // Setting a past date to delete the cookie
        navigate('/login');
    };

    const successMessage = (message) => {
        messageApi.open({
            type: 'success',
            content: message,
        });
    };

    const warningMessage = (message) => {
        messageApi.open({
            type: 'warning',
            content: message,
        });
    };

    const errorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };


    return (
        <Layout style={{minHeight: '100vh'}}>
            {contextHolder}
            <Sider theme="light" style={{position: 'relative', height: '100vh'}} width={240}>
                <div className="sider-header" style={{padding: '16px', background: '#fff'}}>
                    <FormattedMessage id="sider.title" values={{versionNumber: userInfo.versionNumber}}/>
                </div>
                <Menu theme="light"
                      style={{borderRight: 0}}
                      items={siderMenuItems}
                      defaultSelectedKeys={['inventory']}
                      mode="inline"
                      onClick={handleMenuClick}
                />
                <div style={{position: 'absolute', bottom: 0, width: '100%', padding: '16px', borderTop: '1px solid #f0f0f0'}}>
                    <Link onClick={handleLogout} style={{display: 'flex', alignItems: 'center'}}>
                        <LogoutOutlined/>
                        <span style={{marginLeft: '8px'}}>
                            <FormattedMessage id="sider.logout"/>
                        </span>
                    </Link>
                </div>
            </Sider>
            <Layout className="site-layout" style={{minHeight: '100vh', backgroundColor: '#f0f2f5'}}>
                <Content style={{margin: '0 16px', backgroundColor: '#f0f2f5'}}>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Title level={2}><FormattedMessage id="inventoryPage.title"/></Title>
                        <Space>
                            <Tag style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                {intl.formatMessage({id: "inventoryPage.search"}, {method: searchMethod})}
                            </Tag>
                            <DropDownMenuLanguage setLocale={setLocale}/>
                        </Space>
                    </div>
                    <Space direction="vertical" style={{width: '100%'}} gap="large">
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <Flex wrap="wrap" gap="middle">
                                {Object.entries(materialTypes).map(([key, value]) => (
                                    <Button key={key}
                                            type={selectMaterialType === key ? 'primary' : 'text'}
                                            onClick={() => handleChangeType(key)}
                                            size='middle'
                                    >
                                        {value}
                                    </Button>
                                ))}
                            </Flex>
                            <Space>
                                <Button icon={<ToolOutlined/>}
                                        onClick={() => warningMessage(intl.formatMessage({id: "main.underDeveloping"}))}
                                >
                                    <span style={{paddingLeft: '0px'}}><FormattedMessage id="inventoryPage.trackTools"/></span>
                                </Button>
                            </Space>
                        </div>
                        <Table dataSource={selectMaterialType === 'All' ? inventoryData : inventoryData.filter(item => item.type === selectMaterialType)}
                               ref={tableRef}
                               columns={columns}
                               loading={isFetchingData}
                               pagination={false}
                               scroll={{y: '79vh'}}
                               size="middle"
                        />
                    </Space>
                </Content>
            </Layout>
        </Layout>
    );
};

export default InventoryPage;
