import React from 'react';
import {FormattedMessage} from "react-intl";

const currentYear = new Date().getFullYear().toString();

const statusList = [
    {id: 'container-on-going', messageId: 'projectPage.ongoing'},
    {id: 'container-in-progress', messageId: 'projectPage.inProgress'},
    {id: 'container-temporary', messageId: 'projectPage.temporary'},
    {id: 'container-pending-accept', messageId: 'projectPage.pendingAccept'},
    {id: 'container-completed', messageId: 'projectPage.completed'}
];

const generateItems = (startId, count) => {
    return Array.from({length: count}, (_, index) => ({
        name: "Loading",
        jobSite: "Loading",
        id: `Loading${startId + index}`,
        createToYear: currentYear,
        percentage: {}
    }));
};

export const emptyProjectData = statusList.map((status, index) => ({
    id: status.id,
    name: <FormattedMessage id={status.messageId}/>,
    items: generateItems(index * 3 + 1, 6)
}));
