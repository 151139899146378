import {AutoComplete, Badge, Button, Flex, Input, InputNumber, message, Modal, Select, Space, Table} from "antd";
import React, {useEffect, useRef, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {DeleteOutlined, EditOutlined, FileAddOutlined, HistoryOutlined, SearchOutlined} from "@ant-design/icons";

import '../projectPage.css';
import OrderHistoryModal from "./OrderHistoryModal";

const {TextArea} = Input;


const materialTypes = {
    'All': <FormattedMessage id="materialTable.all"/>,
    'Elevator Parts': <FormattedMessage id="materialTable.elevatorParts"/>,
    'Materials': <FormattedMessage id="materialTable.materials"/>,
    'Consumables': <FormattedMessage id="materialTable.consumables"/>,
    'Tools': <FormattedMessage id="materialTable.tools"/>,
    'Others': <FormattedMessage id="materialTable.others"/>,
};

const vendorOptions = [
    {value: 'KINGS ELEVATOR PARTS INC.', label: 'KINGS ELEVATOR PARTS INC.'},
    {value: 'ZZIPCO', label: 'ZZIPCO'},
    {value: 'FLAME CUT STEEL INC.', label: 'FLAME CUT STEEL INC.'},
    {value: 'MEGA ELEVATOR PARTS', label: 'MEGA ELEVATOR PARTS'},
    {value: 'XPRESS DOOR-N-CABS', label: 'XPRESS DOOR-N-CABS'},
    {value: 'TANNER BOLT N NUT INC.', label: 'TANNER BOLT N NUT INC.'},
    {value: 'HOLLISTER WHITNEY', label: 'HOLLISTER WHITNEY'},
    {value: 'GAL', label: 'GAL'},
    {value: 'ESI', label: 'ESI'},
    {value: 'PELLE', label: 'PELLE'},
    {value: 'THE HOME DEPOT', label: 'THE HOME DEPOT'},
    {value: 'Amazon', label: 'Amazon'},
    {value: 'VANTAGE ELEVATION', label: 'VANTAGE ELEVATION'},
    {value: 'Smartrise Engineering', label: 'Smartrise Engineering'},
    {value: 'Canton Elevator', label: 'Canton Elevator'},
    {value: 'Kwong Ming Lighting n Furnature Inc.', label: 'Kwong Ming Lighting n Furnature Inc.'},
    {value: 'Lendy Electric', label: 'Lendy Electric'},
    {value: 'Elevator Products Corp', label: 'Elevator Products Corp'},
    {value: 'DBA JANUS ELEVATOR PRODUCTS', label: 'DBA JANUS ELEVATOR PRODUCTS'},
    {value: 'Giant Lift', label: 'Giant Lift'},
    {value: 'PFLOW INDUSTRIES', label: 'PFLOW INDUSTRIES'},
    {value: 'AUTOZONE', label: 'AUTOZONE'},
    {value: 'DIGIKEY', label: 'DIGIKEY'},
    {value: 'COLLEGE POINT ELEC SUPPLIES', label: 'COLLEGE POINT ELEC SUPPLIES'},
    {value: 'ZORO', label: 'ZORO'},
    {value: 'AIRWELD', label: 'AIRWELD'},
    {value: 'The Peelle Company Ltd.', label: 'The Peelle Company Ltd.'},
    {value: 'LOWE', label: 'LOWE'},
    {value: 'Savaria USA Inc', label: 'Savaria USA Inc'},
    {value: 'GRAINGER', label: 'GRAINGER'},
    {value: 'Certified elevator Escalator Products', label: 'Certified elevator Escalator Products'},
    {value: 'QB11 ELECTRICAL SUPPLY INC.', label: 'QB11 ELECTRICAL SUPPLY INC.'},
    {value: 'Royal Metal Products USA Corp.', label: 'Royal Metal Products USA Corp.'},
];


const OrderMaterialModal = ({title, client, orderNumber, projectNumber, inventoryData, allOrderData, visible, onClose, onSubmit, isSubmittingOrder, tableHeight}) => {
    const intl = useIntl();
    const [messageApi, contextHolder] = message.useMessage();
    const materialTableRef = useRef(null);
    const [selectMaterialType, setSelectMaterialType] = useState('All');
    const [selectedMaterials, setSelectedMaterials] = useState([]);
    const [selectValue, setSelectValue] = useState(null);

    // Order related
    const [orderQuantities, setOrderQuantities] = useState([]);
    const [inputFieldsVisible, setInputFieldsVisible] = useState({});
    const [filteredVendorOptions, setFilteredVendorOptions] = useState(vendorOptions);
    const [searchVendorText, setSearchVendorText] = useState('');

    // History orders
    const [showOrderHistoryModal, setShowOrderHistoryModal] = useState(false);


    // Table columns for complete inventory
    const columns = [
        {title: <FormattedMessage id="inventoryPage.productCode"/>, dataIndex: 'productCode', key: 'productCode', width: 80, fixed: 'left',},
        {title: <FormattedMessage id="inventoryPage.productName"/>, dataIndex: 'productName', key: 'productName', width: 250, fixed: 'left',},
        {title: <FormattedMessage id="inventoryPage.specification"/>, dataIndex: 'specification', key: 'specification', width: 250},
        {
            title: <FormattedMessage id="inventoryPage.costPrice"/>,
            dataIndex: 'costPrice',
            key: 'costPrice',
            width: 100,
            render: (_, record) => {
                const item = orderQuantities.find(item => item.itemId === record.key) || {};
                const costPrice = item.costPrice ?? null;
                return inputFieldsVisible[record.key] ? (
                    <InputNumber value={costPrice}
                                 min={0}
                                 onChange={(value) => handleCostPriceChange(record.key, value)}
                                 style={{width: '100%'}}
                    />
                ) : record.costPrice;  // Use record's costPrice as fallback
            },
        },
        {
            title: <FormattedMessage id="inventoryPage.retailPrice"/>,
            dataIndex: 'retailPrice',
            key: 'retailPrice',
            width: 100,
            render: (_, record) => {
                const item = orderQuantities.find(item => item.itemId === record.key) || {};
                const retailPrice = item.retailPrice ?? null;
                return inputFieldsVisible[record.key] ? (
                    <InputNumber value={retailPrice}
                                 min={0}
                                 onChange={(value) => handleSubmitOrderFieldChange(record.key, 'retailPrice', value)}
                                 style={{width: '100%'}}
                    />
                ) : record.retailPrice;
            },
        },
        {title: <FormattedMessage id="inventoryPage.inStockQuantity"/>, dataIndex: 'inStockQuantity', key: 'inStockQuantity', width: 80},
        {title: <FormattedMessage id="inventoryPage.unit"/>, dataIndex: 'unit', key: 'unit', width: 80},
        {
            title: <FormattedMessage id="inventoryPage.vendor"/>,
            dataIndex: 'vendor',
            key: 'vendor',
            width: 180,
            render: (_, record) => {
                const item = orderQuantities.find(item => item.itemId === record.key) || {};
                return inputFieldsVisible[record.key] ? (
                    <AutoComplete options={filteredVendorOptions}
                                  value={item.vendor}
                                  onChange={(value) => handleSubmitOrderFieldChange(record.key, 'vendor', value)}
                                  onSearch={handleVendorSearch}
                                  style={{width: '100%'}}
                                  placeholder={intl.formatMessage({id: "inventoryPage.selectVendor"})}
                    />
                ) : record.vendor;
            },
        },
        {
            title: <FormattedMessage id="inventoryPage.memo"/>,
            dataIndex: 'memo',
            key: 'memo',
            width: 180,
            render: (_, record) => {
                const item = orderQuantities.find(item => item.itemId === record.key) || {};
                return inputFieldsVisible[record.key] ? (
                    <TextArea value={item.memo}
                              onChange={(e) => handleSubmitOrderFieldChange(record.key, 'memo', e.target.value)}
                              placeholder={intl.formatMessage({id: "inventoryPage.enterMemo"})}
                              autoSize
                    />
                ) : record.memo;
            },
        },
        {
            title: <FormattedMessage id="inventoryPage.quantity"/>,
            dataIndex: 'orderQuantity',
            key: 'orderQuantity',
            width: 140,
            fixed: 'right',
            render: (_, record) => {
                const item = orderQuantities.find(item => item.itemId === record.key) || {};
                const quantity = item.orderQuantity ?? null;

                return (
                    <>
                        {inputFieldsVisible[record.key] && (
                            <Space>
                                <InputNumber min={0}
                                             value={quantity}
                                             onChange={(value) => handleSubmitOrderFieldChange(record.key, 'orderQuantity', value)}
                                             style={{width: '100%'}}
                                />
                                <Button icon={<DeleteOutlined/>}
                                        danger
                                        size="small"
                                        onClick={(value) => handleQuantityDelete(record.key)}
                                />
                            </Space>
                        )}
                        {!inputFieldsVisible[record.key] && (
                            <Space>
                                <Button icon={<EditOutlined/>}
                                        size="small"
                                        onClick={() => toggleNumberInput(record.key, true)}
                                />
                                <Button icon={<DeleteOutlined/>}
                                        danger
                                        size="small"
                                        onClick={(value) => handleQuantityDelete(record.key)}
                                />
                            </Space>
                        )}
                    </>
                );
            },
        }
    ];

    const handleCostPriceChange = (itemId, newCostPrice) => {
        setOrderQuantities(prev => prev.map(item =>
            item.itemId === itemId ? {...item, costPrice: newCostPrice ?? 0, retailPrice: newCostPrice ?? 0} : item
        ));
    };

    const handleSubmitOrderFieldChange = (itemId, field, value) => {
        setOrderQuantities(prev => prev.map(item =>
            item.itemId === itemId ? {...item, [field]: value} : item
        ));
    };

    const handleQuantityDelete = (itemId) => {
        // Hide the input fields for the deleted item
        setInputFieldsVisible(prev => ({...prev, [itemId]: false}));

        // Remove the item from orderQuantities
        setOrderQuantities(prev => prev.filter(item => item.itemId !== itemId));

        // Remove the item from selectedMaterials
        setSelectedMaterials(prev => prev.filter(item => item.productCode !== itemId));
    };

    const handleVendorSearch = (value) => {
        setSearchVendorText(value);
        const filteredOptions = vendorOptions.filter(option =>
            option.label.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredVendorOptions(filteredOptions);
    };

    const toggleNumberInput = (itemId, show) => {
        setInputFieldsVisible(prev => ({...prev, [itemId]: show}));

        if (!orderQuantities.find(item => item.itemId === itemId)) {
            // Try to find the item in selectedMaterials first to preserve assigned fields
            let item = selectedMaterials.find(mat => mat.key === itemId);

            // If not found in selectedMaterials, fall back to inventoryData
            if (!item) {
                item = inventoryData.find(item => item.key === itemId);
            }

            if (item) {
                setOrderQuantities(prev => [
                    ...prev,
                    {
                        itemId,
                        key: itemId,
                        orderQuantity: 1,
                        costPrice: item.costPrice ?? 0,
                        retailPrice: item.retailPrice ?? 0,
                        progress: 0,
                        productName: item.productName,
                        specification: item.specification,
                        unit: item.unit,
                        vendor: item.vendor ?? '',
                        memo: item.memo ?? ''
                    }
                ]);
            }
        }
    };

    useEffect(() => {
        if (!visible) {
            setSelectedMaterials([]);
            setOrderQuantities([]);
            setInputFieldsVisible({});
            setSearchVendorText('');
        } else {
            setSelectMaterialType('All');
        }
    }, [visible]);


    const handleChangeType = (value) => {
        setSelectMaterialType(value);

        if (materialTableRef.current) {
            materialTableRef.current.scrollTo({
                index: 0
            });
        }
    };

    const handleSelectItem = (value, option) => {
        const foundItem = inventoryData.find(item => item.productCode === value);

        if (foundItem) {
            // Check if it's already selected
            const isAlreadySelected = selectedMaterials.some(
                (mat) => mat.productCode === foundItem.productCode
            );
            if (!isAlreadySelected) {
                // Create a new array with the added item
                const newSelectedMaterials = [...selectedMaterials, foundItem];

                // Sort the new array by productCode in ascending order
                newSelectedMaterials.sort((a, b) => a.productCode.localeCompare(b.productCode));

                // Update the state with the sorted array
                setSelectedMaterials(newSelectedMaterials);
            } else {
                warningMessage(<FormattedMessage id="projectPage.duplicatedMaterial"/>);
            }
            setSelectValue(null);
        }
    };

    const categoryCounts = selectedMaterials.reduce((acc, item) => {
        const parts = item.productCategory?.split(' ').slice(1).join(' ');
        acc[parts] = (acc[parts] || 0) + 1;
        return acc;
    }, {});

    // Inside your OrderMaterialModal component

    const handleSubmit = () => {
        // Check 1: Ensure there is at least one material in the table
        if (selectedMaterials.length < 1) {
            warningMessage(<FormattedMessage id="projectPage.submitOrder.empty"/>);
            return;
        }

        // Check 2: Ensure each orderQuantity is defined and >=1
        const invalidItems = selectedMaterials.filter(material => {
            const orderItem = orderQuantities.find(q => q.itemId === material.productCode);
            return !orderItem || orderItem.orderQuantity < 1;
        });

        if (invalidItems.length > 0) {
            warningMessage(<FormattedMessage id="projectPage.submitOrder.invalidQuantities"/>);
            return;
        }

        // If all checks pass, proceed to submit
        onSubmit(orderNumber, orderQuantities);
    };

    const getTotalSelectedPrice = () => {
        const total = orderQuantities.reduce((sum, item) => {
            const quantity = item.orderQuantity || 0;
            const price = item.retailPrice || 0;
            return sum + quantity * price;
        }, 0);

        return new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(total);
    };

    const formatHistoryOrder = (allOrderData, projectNumber) => {
        // Step 1: Filter orders by projectNumber
        const ordersForProject = allOrderData.filter(order => order.projectId === projectNumber);

        // Step 2: Flatten all orderDetails into one list
        const combinedDetails = ordersForProject.flatMap(order => order.orderDetails || []);

        // Step 3: Remove duplicate items based on 'key'
        const uniqueDetails = [];
        const seenKeys = new Set();

        for (const detail of combinedDetails) {
            if (!seenKeys.has(detail.key)) {
                seenKeys.add(detail.key);
                uniqueDetails.push(detail);
            }
        }

        // Step 4: Sort the unique details by itemId
        uniqueDetails.sort((a, b) => {
            return String(a.itemId).localeCompare(String(b.itemId));
        });

        return uniqueDetails;
    };

    const handleAddHistoryMaterial = (selectedHistoryMaterials) => {
        let newSelectedMaterials = [...selectedMaterials];

        // Use a for...of loop for early termination on duplicate
        for (let historyItem of selectedHistoryMaterials) {
            // Check if the key is already in the selected materials
            const duplicate = newSelectedMaterials.some(mat => mat.key === historyItem.key);

            if (duplicate) {
                warningMessage(intl.formatMessage({id: "orderPage.duplicateItem"}))
                return;
            }

            // Find matching inventory item
            const inventoryItem = inventoryData.find(item => item.key === historyItem.key);
            if (inventoryItem) {
                newSelectedMaterials.push({
                    ...inventoryItem,
                    costPrice: historyItem.costPrice,
                    retailPrice: historyItem.retailPrice,
                    vendor: historyItem.vendor,
                    memo: historyItem.memo
                });
            }
        }

        // Sort the materials by key after processing all items without duplicates
        newSelectedMaterials.sort((a, b) => {
            return String(a.key).localeCompare(String(b.key));
        });

        // Update state with sorted array and close the modal
        setSelectedMaterials(newSelectedMaterials);
        setShowOrderHistoryModal(false);
    };

    const successMessage = (message) => {
        messageApi.open({
            type: 'success',
            content: message,
        });
    };

    const warningMessage = (message) => {
        messageApi.open({
            type: 'warning',
            content: message,
        });
    };

    const errorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };


    return (
        <div>{contextHolder}
            <Modal title={title}
                   open={visible}
                   onCancel={onClose}
                   centered
                   width="80%"
                   footer={null}
                   destroyOnClose
                   maskClosable={false}
            >
                <div style={{maxHeight: '70vh', height: '70vh'}}>
                    <Space direction="vertical" style={{width: '100%'}} gap="large">
                        <Flex wrap="wrap" gap="large">
                            {Object.entries(materialTypes).map(([key, value]) => {
                                // key is the category name in your materialTypes object, e.g. "All", "Elevator Parts", etc.
                                const count = categoryCounts[key] || 0; // get the count from the dictionary

                                return (
                                    <Badge key={key}
                                           count={key === 'All' ? selectedMaterials.length : count}
                                           offset={[5, 0]}
                                           size="small"
                                    >
                                        <Button type={selectMaterialType === key ? 'primary' : 'test'}
                                                onClick={() => handleChangeType(key)}
                                                size='middle'
                                        >
                                            {value}
                                        </Button>
                                    </Badge>
                                );
                            })}
                        </Flex>
                        <Flex wrap="wrap" gap="large">
                            <Select placeholder={intl.formatMessage({id: "projectPage.typeInToSearch"})}
                                    allowClear
                                    suffixIcon={<SearchOutlined/>}
                                    value={selectValue}
                                    showSearch
                                    style={{width: "80vh"}}
                                    onSelect={(value, option) => handleSelectItem(value, option)}
                                    filterOption={(inputValue, option) =>
                                        option.label?.toLowerCase().includes(inputValue.toLowerCase())
                                    }
                            >
                                {inventoryData.map((item) => (
                                    <Select.Option key={item.productCode}
                                                   value={item.productCode}
                                                   label={`${item.productCode} ${item.productName} ${item.specification}`}
                                    >
                                        <span style={{display: 'inline-block', width: '60px'}}>{item.productCode}</span>
                                        <span style={{display: 'inline-block', width: '310px'}}>{item.productName}</span>
                                        <span>{item.specification}</span>
                                    </Select.Option>
                                ))}
                            </Select>
                            <Button icon={<HistoryOutlined/>}
                                    onClick={() => setShowOrderHistoryModal(true)}
                            >
                                <Space>
                                    <FormattedMessage id="orderPage.orderHistory"/>
                                </Space>
                            </Button>
                        </Flex>
                        <div style={{maxHeight: tableHeight, overflowY: 'auto'}}>
                            <Table ref={materialTableRef}
                                   dataSource={selectMaterialType === 'All' ? selectedMaterials : selectedMaterials.filter(item => item.type === selectMaterialType)}
                                   columns={columns}
                                   rowKey="productCode"
                                   virtual
                                   pagination={false}
                                   scroll={{x: 1300, y: tableHeight}}
                                   size="middle"
                            />
                        </div>
                    </Space>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <Space>
                        {intl.formatMessage({id: "materialTable.orderNumber.detail"}, {orderNumber: orderNumber})}
                        <br/>
                        {intl.formatMessage({id: "inventoryPage.totalPrice"}, {totalPrice: getTotalSelectedPrice()})}
                    </Space>
                    <div>
                        <Space>
                            <Button key="cancel"
                                    onClick={onClose}
                            >
                                <FormattedMessage id="main.cancel"/>
                            </Button>
                            <Button key="submit"
                                    type="primary"
                                    onClick={handleSubmit}
                                    loading={isSubmittingOrder}
                            >
                                <FormattedMessage id="main.submit"/>
                            </Button>
                        </Space>
                    </div>
                </div>
            </Modal>
            <OrderHistoryModal title={intl.formatMessage({id: "orderPage.clientOrderHistory"}, {client: client})}
                               visible={showOrderHistoryModal}
                               onClose={() => setShowOrderHistoryModal(false)}
                               onAddHistoryMaterial={handleAddHistoryMaterial}
                               orderHistory={formatHistoryOrder(allOrderData, projectNumber)}
                               tableHeight={tableHeight * 0.8}
            />
        </div>
    );
};

export default OrderMaterialModal;
