import {Button, Checkbox, Modal, Typography} from "antd";
import React, {useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import axios from "axios";

import '../projectPage.css';

const {Link, Text} = Typography;

const UploadFileHintModal = ({visible, onClose, onStopShowAgain}) => {
    const intl = useIntl();
    const [dontShowAgain, setDontShowAgain] = useState(false);


    const hintContent = (
        <div>
            <FormattedMessage id="projectPage.fileUploadHint"/>
            <div style={{marginTop: '20px'}}>
                <Checkbox checked={dontShowAgain}
                          onChange={(e) => setDontShowAgain(e.target.checked)}
                >
                    <FormattedMessage id="main.dontShowAgain"/>
                </Checkbox>
            </div>
        </div>
    );


    return (
        <Modal title={intl.formatMessage({id: "main.systemInformation"})}
               open={visible}
               onCancel={onClose}
               width="550px"
               zIndex={1030}
               footer={
                   <Button type="primary" onClick={() => onStopShowAgain(dontShowAgain)}>
                       <FormattedMessage id="main.ok"/>
                   </Button>
               }
        >
            {hintContent}
        </Modal>
    );
};

export default UploadFileHintModal;
