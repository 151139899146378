import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {FormattedDate, FormattedMessage, useIntl} from "react-intl";
import {useCookies} from "react-cookie";
import axios from "axios";

import {v4 as uuidv4} from "uuid";
import {Card, Col, Empty, Flex, Input, Layout, Menu, message, Row, Space, Statistic, Typography} from 'antd';
import {AccountBookOutlined, CreditCardOutlined, FundProjectionScreenOutlined, LogoutOutlined, PieChartOutlined, SettingOutlined, ShopOutlined,} from '@ant-design/icons';

import dayjs from 'dayjs';

import DropDownMenuLanguage from "./components/DropDownMenuLanguage";
import UpdateLogModal from "./components/UpdateLogModal";
import ClusteredMap from "./components/ClusteredMap";
import DropDownMenuYear from "./components/DropDownMenuYear";


dayjs.extend(require('dayjs/plugin/utc'))
dayjs.extend(require('dayjs/plugin/timezone'))

const {Header, Content, Footer, Sider} = Layout;
const {SubMenu} = Menu;
const {Text, Title, Link} = Typography;
const {TextArea} = Input;

let today = new Date();


function getSiderMenuItem(label, key, icon, children, type) {
    return {key, icon, children, label, type};
}

const siderMenuItems = [
    getSiderMenuItem(<FormattedMessage id="sider.dashboard"/>, 'dashboard', <PieChartOutlined/>),
    getSiderMenuItem(<FormattedMessage id="sider.projects"/>, 'projects', <FundProjectionScreenOutlined/>),
    getSiderMenuItem(<FormattedMessage id="sider.order"/>, 'order', <CreditCardOutlined/>),
    getSiderMenuItem(<FormattedMessage id="sider.inventory"/>, 'inventory', <ShopOutlined/>),
    getSiderMenuItem(<FormattedMessage id="sider.accounting"/>, 'accounting', <AccountBookOutlined/>),
    getSiderMenuItem(<FormattedMessage id="sider.settings"/>, 'settings', <SettingOutlined/>),
];


const DashboardPage = ({setLocale}) => {
    const navigate = useNavigate();
    const mapToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
    const intl = useIntl(); // Hook to get the intl object
    const [messageApi, contextHolder] = message.useMessage();
    const [cookies, setCookie] = useCookies(['userInfo']);
    const [userInfo, setUserInfo] = useState(cookies.userInfo || {});
    const [showUpdateLog, setShowUpdateLog] = useState(cookies.userInfo.showLog ?? false);

    const [csrfToken, setCsrfToken] = useState(null);
    const [greeting, setGreeting] = useState('dashboardPage.goodMorning');

    const [isFetchingStats, setIsFetchingStats] = useState(false);
    const [allProjects, setAllProjects] = useState([]);
    const [projectCounts, setProjectCounts] = useState({'on-going': 0, 'in-progress': 0, 'temporary': 0, 'pending-accept': 0, 'completed': 0});
    const [projectCoordinates, setProjectCoordinates] = useState([]);

    const [selectedYear, setSelectedYear] = useState(dayjs().format('YYYY'));


    useEffect(() => {
        fetchProjectCount();
    }, []);

    useEffect(() => {
        const updateGreeting = () => {
            const now = new Date();
            const hour = now.getHours();
            let greetingId;

            if (hour >= 5 && hour < 12) {
                greetingId = "dashboardPage.goodMorning";
            } else if (hour >= 12 && hour < 18) {
                greetingId = "dashboardPage.goodAfternoon";
            } else {
                greetingId = "dashboardPage.goodEvening";
            }

            setGreeting(greetingId);

            // Calculate the time until the next greeting change
            let nextChange;
            if (hour >= 5 && hour < 12) {
                nextChange = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 12, 0, 0) - now;
            } else if (hour >= 12 && hour < 18) {
                nextChange = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 18, 0, 0) - now;
            } else {
                const tomorrow = new Date(now);
                tomorrow.setDate(tomorrow.getDate() + 1);
                nextChange = new Date(tomorrow.getFullYear(), tomorrow.getMonth(), tomorrow.getDate(), 5, 0, 0) - now;
            }

            setTimeout(updateGreeting, nextChange);
        };

        updateGreeting();
    }, []);

    useEffect(() => {
        // Define a function to filter projects based on selectedYear
        const filterProjectsByYear = (projects, year) => {
            return projects.filter(project => {
                if (project.completeDate) {
                    // Extract the year from completeDate
                    const completeYear = dayjs(project.completeDate).format('YYYY');
                    return completeYear === year;
                } else {
                    // Fallback to createToYear
                    return project.createToYear === year;
                }
            });
        };

        // Filter the projects based on selectedYear
        const filteredProjects = filterProjectsByYear(allProjects, selectedYear);

        // Initialize counts for each status
        const counts = {
            'on-going': 0,
            'in-progress': 0,
            'temporary': 0,
            'pending-accept': 0,
            'completed': 0
        };

        // Iterate through each filtered project to count statuses
        filteredProjects.forEach(project => {
            const status = project.status;
            if (counts.hasOwnProperty(status)) {
                counts[status] += 1;
            } else {
                // Optionally handle unexpected statuses
                console.warn(`Unexpected project status: ${status}`);
            }
        });

        // Update the projectCounts state
        setProjectCounts(counts);

        // Extract coordinates for the map
        setProjectCoordinates(filteredProjects);

    }, [allProjects, selectedYear]);


    const fetchProjectCount = () => {
        setIsFetchingStats(true);
        axios.post(
            "/api/fetch_project_count/",
            {
                parameters: {
                    userId: userInfo.userId,
                    authKey: userInfo.authKey,
                    os: userInfo.os,
                    datetime: new Date()
                },
                requestName: 'fetch_projects',
                jobId: uuidv4()
            },
            {
                headers: {'X-CSRFToken': csrfToken},
                withCredentials: true
            }
        )
            .then(response => {
                setIsFetchingStats(false);
                if (response.data.status === "success") {
                    const projects = response.data.locationCount;

                    // Process each project to add createToYear
                    const processedProjects = projects.map(project => {
                        let createToYear;
                        if (project.completeDate) {
                            // Extract the year from completeDate
                            const completeDayjs = dayjs(project.completeDate);
                            if (completeDayjs.isValid()) {
                                createToYear = completeDayjs.format('YYYY');
                            } else {
                                console.warn(`Invalid completeDate format for project ID: ${project.id}. Using current year as fallback.`);
                                createToYear = dayjs().format('YYYY'); // Fallback to current year
                            }
                        } else {
                            // Use the current year
                            createToYear = dayjs().format('YYYY');
                        }

                        return {
                            ...project,
                            createToYear
                        };
                    });

                    setAllProjects(processedProjects); // Store all processed projects
                } else {
                    errorMessage("Failed to fetch project count:\n" + response.data.responseData.errorMessage);
                    console.error("Failed to fetch project count:\n", response.data.responseData.errorMessage);
                }
            })
            .catch(error => {
                setIsFetchingStats(false);
                errorMessage("Error fetching project count: " + (error.message || error));
                console.error("Error fetching project count:", error);
            });
    };

    const handleStopShowLog = (dontShowAgain) => {
        setShowUpdateLog(false);

        if (!dontShowAgain) {
            return;
        }

        axios.post(
            "/api/switch_show_log/",
            {
                parameters: {
                    userId: userInfo.userId,
                    showLog: false,
                    os: userInfo.os,
                    authKey: userInfo.authKey,
                    datetime: new Date()
                },
                requestName: 'switch_show_log',
                jobId: uuidv4()
            },
            {
                headers: {'X-CSRFToken': csrfToken},
                withCredentials: true
            }
        ).then(response => {
            if (response.data.status === "success") {
                const updatedUserInfo = {
                    ...userInfo,
                    showLog: false
                };
                setUserInfo(updatedUserInfo);
                setCookie('userInfo', updatedUserInfo, {path: '/'});
            } else {
                errorMessage("Failed to switch showing log:\n", response.data.responseData.errorMessage);
                console.error("Failed to switch showing log:\n", response.data.responseData.errorMessage);
            }
        }).catch(error => {
            errorMessage("Error switching showing log:", error);
            console.error("Error switching showing log:", error);
        });
    };

    const handleMenuClick = (e) => {
        switch (e.key) {
            case "dashboard":
                break;
            case "projects":
                navigate('/project');
                break;
            case "order":
                navigate('/order');
                break;
            case "inventory":
                navigate('/inventory');
                break;
            case "accounting":
                warningMessage(intl.formatMessage({id: "main.underDeveloping"}))
                // navigate('/accounting');
                break;
            case "settings":
                navigate('/settings');
                break;
            default:
            // Handle other cases or do nothing
        }
    };

    const handleLogout = () => {
        setCookie('userInfo', '', {path: '/', expires: new Date(0)}); // Setting a past date to delete the cookie
        navigate('/login');
    };

    const successMessage = (message) => {
        messageApi.open({
            type: 'success',
            content: message,
        });
    };

    const warningMessage = (message) => {
        messageApi.open({
            type: 'warning',
            content: message,
        });
    };

    const errorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    const loadingMessage = (message) => {
        messageApi.open({
            type: 'loading',
            content: message,
            key: 'loadingMessage'
        });
    };


    return (
        <Layout style={{minHeight: '100vh'}}>
            {contextHolder}
            <Sider theme="light" style={{position: 'relative', height: '100vh'}} width={240}>
                <div className="sider-header" style={{padding: '16px', background: '#fff'}}>
                    <FormattedMessage id="sider.title" values={{versionNumber: userInfo.versionNumber}}/>
                </div>
                <Menu theme="light"
                      style={{borderRight: 0}}
                      items={siderMenuItems}
                      defaultSelectedKeys={['dashboard']}
                      mode="inline"
                      onClick={handleMenuClick}
                />
                <div style={{position: 'absolute', bottom: 0, width: '100%', padding: '16px', borderTop: '1px solid #f0f0f0'}}>
                    <Link onClick={handleLogout} style={{display: 'flex', alignItems: 'center'}}>
                        <LogoutOutlined/>
                        <span style={{marginLeft: '8px'}}>
                            <FormattedMessage id="sider.logout"/>
                        </span>
                    </Link>
                </div>
            </Sider>
            <Layout className="site-layout" style={{minHeight: '100vh', backgroundColor: '#f0f2f5'}}>
                <Content style={{margin: '0 16px', backgroundColor: '#f0f2f5'}}>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Title level={2}><FormattedMessage id={greeting} values={{nickname: userInfo.nickname || ''}}/></Title>
                        <Space>
                            <DropDownMenuYear onYearChange={(year) => setSelectedYear(year)}/>
                            <DropDownMenuLanguage setLocale={setLocale}/>
                        </Space>
                    </div>
                    <Text type="secondary" style={{marginTop: '-10px', display: 'block'}}>
                        <FormattedDate value={today} year="numeric" month="numeric" day="numeric" weekday="long"/>
                    </Text>
                    <Row>
                        <Col span={16}>
                            <Flex justify='space-between' align='flex-start' style={{marginTop: '2vh'}}>
                                <Card bordered={false} style={{minHeight: '10vh', width: '18.4%'}} size="small">
                                    <Statistic title={<FormattedMessage id="projectPage.ongoing"/>} value={projectCounts['on-going']} loading={isFetchingStats}/>
                                </Card>
                                <Card bordered={false} style={{minHeight: '10vh', width: '18.4%'}} size="small">
                                    <Statistic title={<FormattedMessage id="projectPage.inProgress"/>} value={projectCounts['in-progress']} loading={isFetchingStats}/>
                                </Card>
                                <Card bordered={false} style={{minHeight: '10vh', width: '18.4%'}} size="small">
                                    <Statistic title={<FormattedMessage id="projectPage.temporary"/>} value={projectCounts['temporary']} loading={isFetchingStats}/>
                                </Card>
                                <Card bordered={false} style={{minHeight: '10vh', width: '18.4%'}} size="small">
                                    <Statistic title={<FormattedMessage id="projectPage.pendingAccept"/>} value={projectCounts['pending-accept']} loading={isFetchingStats}/>
                                </Card>
                                <Card bordered={false} style={{minHeight: '10vh', width: '18.4%'}} size="small">
                                    <Statistic title={<FormattedMessage id="projectPage.completed"/>} value={projectCounts['completed']} loading={isFetchingStats}/>
                                </Card>
                            </Flex>
                            <Flex justify='space-between' align='flex-start' style={{marginTop: '2vh'}}>
                                <div style={{width: '49%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <ClusteredMap projectCoordinates={projectCoordinates} mapToken={mapToken}/>
                                </div>
                                <Card bordered={false} style={{minHeight: '30vh', width: '49%'}} size="small">
                                    <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <Empty style={{margin: '20px'}}/>
                                    </div>
                                </Card>
                            </Flex>
                            <Flex justify='space-between' align='flex-start' style={{marginTop: '2vh'}}>
                                <Card bordered={false} style={{minHeight: '40vh', width: '100%'}} size="small">
                                    <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <Empty style={{margin: '20px'}}/>
                                    </div>
                                </Card>
                            </Flex>
                        </Col>
                        <Col span={8}>
                            <Flex justify='space-around' align='flex-start' style={{marginTop: '2vh'}}>
                                <Card bordered={false} style={{minHeight: '84vh', width: '92%'}} size="small">
                                    <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <Empty style={{margin: '20px'}}/>
                                    </div>
                                </Card>
                            </Flex>
                        </Col>
                    </Row>
                    <UpdateLogModal visible={showUpdateLog}
                                    version={userInfo.versionNumber}
                                    onClose={() => setShowUpdateLog(false)}
                                    onStopShowAgain={handleStopShowLog}
                    />
                </Content>
            </Layout>
        </Layout>
    );
};

export default DashboardPage;
