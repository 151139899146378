import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from "react-intl";
import {useNavigate} from 'react-router-dom';
import {useCookies} from 'react-cookie';
import axios from "axios";
import dayjs from "dayjs";

import {Button, Checkbox, DatePicker, Empty, Flex, Form, Input, Layout, Menu, message, Modal, Select, Space, Typography} from 'antd';
import {
    AccountBookOutlined,
    CreditCardOutlined,
    FileAddOutlined,
    FundProjectionScreenOutlined,
    LogoutOutlined,
    PieChartOutlined,
    SettingOutlined,
    ShopOutlined,
} from '@ant-design/icons';
import {v4 as uuidv4} from "uuid";

import AddressAutocomplete from "./components/AddressAutocomplete";
import Container from "./project-kanban/container";
import Items from "./project-kanban/items";
import DropDownMenuYear from "./components/DropDownMenuYear"
import DropDownMenuLanguage from "./components/DropDownMenuLanguage";

import {emptyProjectData} from "./project-kanban/emptyProjects";

import './projectPage.css'


const {Option} = Select;

let today = new Date();
today.setHours(0, 0, 0, 0);


// Layouts
const {Header, Content, Footer, Sider} = Layout;
const {SubMenu} = Menu;
const {Link, Title} = Typography;


// Sider Bar Menu
function getSiderMenuItem(label, key, icon, children, type) {
    return {key, icon, children, label, type};
}

const siderMenuItems = [
    getSiderMenuItem(<FormattedMessage id="sider.dashboard"/>, 'dashboard', <PieChartOutlined/>),
    getSiderMenuItem(<FormattedMessage id="sider.projects"/>, 'projects', <FundProjectionScreenOutlined/>),
    getSiderMenuItem(<FormattedMessage id="sider.order"/>, 'order', <CreditCardOutlined/>),
    getSiderMenuItem(<FormattedMessage id="sider.inventory"/>, 'inventory', <ShopOutlined/>),
    getSiderMenuItem(<FormattedMessage id="sider.accounting"/>, 'accounting', <AccountBookOutlined/>),
    getSiderMenuItem(<FormattedMessage id="sider.settings"/>, 'settings', <SettingOutlined/>),
];

const containerTypes = {
    'container-on-going': <FormattedMessage id="projectPage.ongoing"/>,
    'container-in-progress': <FormattedMessage id="projectPage.inProgress"/>,
    'container-temporary': <FormattedMessage id="projectPage.temporary"/>,
    'container-pending-accept': <FormattedMessage id="projectPage.pendingAccept"/>,
    'container-completed': <FormattedMessage id="projectPage.completed"/>
};


const ProjectPage = ({setLocale}) => {
    const intl = useIntl(); // Hook to get the intl object
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();
    const mapToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

    const [csrfToken, setCsrfToken] = useState(null);
    const [cookies, setCookie] = useCookies(['userInfo']);
    const [userInfo, setUserInfo] = useState(cookies.userInfo || {});

    // project kanban board
    const [isRenderingProjects, setIsRenderingProjects] = useState(false);
    const [isCreatingProject, setIsCreatingProject] = useState(false);
    const [isEditingProject, setIsEditingProject] = useState(false);
    const [isDeletingProject, setIsDeletingProject] = useState(false);
    const [currentContainerId, setCurrentContainerId] = useState('container-on-going');
    const [containers, setContainers] = useState(emptyProjectData);

    const [selectedYear, setSelectedYear] = useState(dayjs().format('YYYY'));

    // Add project
    const [showAddProjectModal, setShowAddProjectModal] = useState(false);
    const [addProjectForm] = Form.useForm();
    const [addMaterialForm] = Form.useForm();
    const [workPermitDate, setWorkPermitDate] = useState(null);
    const [workPermitApproval, setWorkPermitApproval] = useState(false);
    const [drawingApprovalDate, setDrawingApprovalDate] = useState(null);
    const [drawingApproval, setDrawingApproval] = useState(false);
    const [projectCoordinates, setProjectCoordinates] = useState([]);

    // useEffect(() => {  // TODO: for testing
    //     console.log(containers);
    // }, [containers]);

    useEffect(() => {
        axios.get('/api/get_csrf_token/')
            .then(response => {
                setCsrfToken(response.data.csrfToken);
            })
            .catch(error => console.error('Error fetching CSRF token:', error));
    }, []);

    useEffect(() => {
        fetchProjects();
    }, []);


    const handleLogout = () => {
        setCookie('userInfo', '', {path: '/', expires: new Date(0)}); // Setting a past date to delete the cookie
        navigate('/login');
    };

    const fetchProjects = () => {
        setIsRenderingProjects(true);
        axios.post(
            "/api/fetch_projects/",
            {
                parameters: {
                    userId: userInfo.userId,
                    authKey: userInfo.authKey,
                    os: userInfo.os,
                    datetime: new Date()
                },
                requestName: 'fetch_projects',
                jobId: uuidv4()
            },
            {
                headers: {'X-CSRFToken': csrfToken},
                withCredentials: true
            }
        ).then(response => {
            if (response.data.status === "success") {
                const projectData = response.data.responseData;
                setContainers([
                    {id: 'container-on-going', name: <FormattedMessage id="projectPage.ongoing"/>, items: projectData['on-going'] || []},
                    {id: 'container-in-progress', name: <FormattedMessage id="projectPage.inProgress"/>, items: projectData['in-progress'] || []},
                    {id: 'container-temporary', name: <FormattedMessage id="projectPage.temporary"/>, items: projectData['temporary'] || []},
                    {id: 'container-pending-accept', name: <FormattedMessage id="projectPage.pendingAccept"/>, items: projectData['pending-accept'] || []},
                    {id: 'container-completed', name: <FormattedMessage id="projectPage.completed"/>, items: projectData['completed'] || []},
                ]);
                setIsRenderingProjects(false);
            } else {
                errorMessage("Failed to fetch projects:\n", response.data.responseData.errorMessage);
                console.error("Failed to fetch projects:\n", response.data.responseData.errorMessage);
            }
        }).catch(error => {
            errorMessage("Error fetching projects:", error);
            console.error("Error fetching projects:", error);
        });
    };

    const handleAddProject = (formData) => {
        setIsCreatingProject(true);

        const newProject = {
            containerId: currentContainerId,
            id: uuidv4(),
            name: formData['project-name'],
            jobSite: formData['job-site'],
            coordinates: projectCoordinates,
            installationGroup: [],
            wiringGroup: [],
            deliveryGroup: [],
            elevatorData: [],
            code: formData['code-compliant'],
            signDate: formData['contract-signed-date'],
            createToYear: selectedYear,
            createDate: today,
            startDate: formData['project-start-date'],
            drawingApprovalDate: drawingApprovalDate,
            workPermitDate: workPermitDate,
            materialOrderDate: null,
            materialArriveDate: null,
            runPlatformDate: null,
            doorDeliveredDate: null,
            finishElevatorDate: null,
            acceptDate: null,
            completeDate: formData['project-complete-date'],
            dobStartDate: null,
            dobEndDate: null,
            dobDetails: null,
            memoCount: 0,
            percentage: {
                contractSigned: 5,
                drawingApproved: 15,
                materialOrdered: 10,
                materialArrived: 10,
                runPlatform: 15,
                doorDelivered: 15,
                elevatorFinished: 15,
                acceptance: 15
            }
        };

        const projectInfo = Object.fromEntries(
            Object.entries(newProject).map(([key, value]) => [key, value === undefined ? null : value])
        );

        axios.post(
            "/api/create_project/",
            {
                parameters: {
                    userId: userInfo.userId,
                    os: userInfo.os,
                    projectInfo: projectInfo,
                    authKey: userInfo.authKey,
                    datetime: new Date()
                },
                requestName: 'create_project',
                jobId: uuidv4()
            },
            {
                headers: {'X-CSRFToken': csrfToken},
                withCredentials: true
            }
        ).then(response => {
            setIsCreatingProject(false);
            if (response.data.status === "success") {
                const newProjectId = response.data.responseData;
                newProject['id'] = 'item-' + newProjectId['projectId'];

                const container = containers.find((item) => item.id === currentContainerId);
                container.items.push(newProject);
                setContainers([...containers]);
                setDrawingApproval(false);
                setDrawingApprovalDate(null);
                setWorkPermitDate(null);
                setWorkPermitApproval(false);
                setShowAddProjectModal(false);
                setProjectCoordinates([]);

                setTimeout(() => {
                    addProjectForm.resetFields();
                    successMessage(intl.formatMessage({id: "projectPage.createProject.success"}, {projectName: projectInfo.name}));
                }, 300);
            } else {
                errorMessage("Failed to create project:\n", response.data.responseData.errorMessage);
                console.error("Failed to create project:\n", response.data.responseData.errorMessage);
            }
        }).catch(error => {
            setIsCreatingProject(false);
            console.error("Error creating project:", error);
        });
    };

    const handleMenuClick = (e) => {
        switch (e.key) {
            case "dashboard":
                navigate('/dashboard');
                break;
            case "projects":
                break;
            case "order":
                navigate('/order');
                break;
            case "inventory":
                navigate('/inventory');
                break;
            case "accounting":
                warningMessage(intl.formatMessage({id: "main.underDeveloping"}))
                // navigate('/accounting');
                break;
            case "settings":
                navigate('/settings');
                break;
            default:
            // Handle other cases or do nothing
        }
    };

    const handleMoveProject = (projectId, projectName, originalContainerId, targetContainerId) => {
        loadingMessage(intl.formatMessage({id: 'projectPage.movingProject'}, {projectName: projectName}));

        axios.post(
            "/api/move_project/",
            {
                parameters: {
                    userId: userInfo.userId,
                    os: userInfo.os,
                    projectId: projectId,
                    originalContainerId: originalContainerId,
                    targetContainerId: targetContainerId,
                    authKey: userInfo.authKey,
                    datetime: new Date()
                },
                requestName: 'move_project',
                jobId: uuidv4()
            },
            {
                headers: {'X-CSRFToken': csrfToken},
                withCredentials: true
            }
        ).then(response => {
            messageApi.destroy('loadingMessage');
            if (response.data.status === "success") {
                // Find the original container
                const originalContainerIndex = containers.findIndex(container => container.id === originalContainerId);
                const targetContainerIndex = containers.findIndex(container => container.id === targetContainerId);

                // Find the project in the original container
                const projectIndex = containers[originalContainerIndex].items.findIndex(item => item.id === projectId);
                const project = containers[originalContainerIndex].items[projectIndex];

                // Remove the project from the original container
                const updatedOriginalContainer = {
                    ...containers[originalContainerIndex],
                    items: containers[originalContainerIndex].items.filter(item => item.id !== projectId)
                };

                // Conditionally update the completeDate
                const updatedProject = targetContainerId === "container-completed"
                    ? {...project, containerId: targetContainerId, completeDate: dayjs().startOf('day').toDate()}
                    : {...project, containerId: targetContainerId};

                // Add the project to the target container
                const updatedTargetContainer = {
                    ...containers[targetContainerIndex],
                    items: [...containers[targetContainerIndex].items, {...updatedProject, containerId: targetContainerId}]
                };

                // Update the state
                const updatedContainers = containers.map((container, index) => {
                    if (index === originalContainerIndex) {
                        return updatedOriginalContainer;
                    } else if (index === targetContainerIndex) {
                        return updatedTargetContainer;
                    } else {
                        return container;
                    }
                });

                setContainers(updatedContainers);
                successMessage(intl.formatMessage({id: 'projectPage.moveProject.success'}, {projectName: projectName}));
            } else {
                errorMessage("Failed to move project:\n", response.data.responseData.errorMessage);
                console.error("Failed to move project:\n", response.data.responseData.errorMessage);
            }
        }).catch(error => {
            errorMessage("Error moving project:", error);
            console.error("Error moving project:", error);
        });
    };

    const handleEditProject = (updatedProjectData) => {
        setIsEditingProject(true);
        const containerId = updatedProjectData['containerId'];
        const projectId = updatedProjectData['id'];

        axios.post(
            "/api/update_project/",
            {
                parameters: {
                    userId: userInfo.userId,
                    os: userInfo.os,
                    updatedProjectInfo: updatedProjectData,
                    authKey: userInfo.authKey,
                    datetime: new Date()
                },
                requestName: 'update_project',
                jobId: uuidv4()
            },
            {
                headers: {'X-CSRFToken': csrfToken},
                withCredentials: true
            }
        ).then(response => {
            setIsEditingProject(false);
            if (response.data.status === "success") {
                successMessage(intl.formatMessage({id: 'projectPage.updateProject.success'}));
                setContainers(currentContainers =>
                    currentContainers.map(container => {
                        // Check if this is the container that contains the project to be updated
                        if (container.id === containerId) {
                            // Map over the items in the container to find and update the specific project
                            const updatedItems = container.items.map(item => {
                                if (item.id === projectId) {
                                    // This is the project to be updated, so return the updated project data
                                    return updatedProjectData;
                                }
                                // This is not the project we're looking for, so return it unchanged
                                return item;
                            });

                            // Return an updated container with the updated project item
                            return {...container, items: updatedItems};
                        }
                        // This is not the container we're looking for, so return it unchanged
                        return container;
                    })
                );
            } else {
                errorMessage("Failed to update project:\n", response.data.responseData.errorMessage);
                console.error("Failed to update project:\n", response.data.responseData.errorMessage);
            }
        }).catch(error => {
            setIsEditingProject(false);
            errorMessage("Error updating project:", error);
            console.error("Error updating project:", error);
        });
    };

    const handleDeleteProject = (projectId, projectName) => {
        setIsDeletingProject(true);
        axios.post(
            "/api/delete_project/",
            {
                parameters: {
                    userId: userInfo.userId,
                    os: userInfo.os,
                    projectId: projectId,
                    authKey: userInfo.authKey,
                    datetime: new Date()
                },
                requestName: 'delete_project',
                jobId: uuidv4()
            },
            {
                headers: {'X-CSRFToken': csrfToken},
                withCredentials: true
            }
        ).then(response => {
            setIsDeletingProject(false);
            if (response.data.status === "success") {
                setContainers(containers.map(container => ({
                    ...container,
                    items: container.items.filter(item => item.id !== projectId)
                })));
                setTimeout(() => {
                    successMessage(intl.formatMessage({id: "projectPage.deleteProject.success"}, {projectName: projectName}));
                }, 300);
            } else {
                errorMessage("Failed to delete project:\n", response.data.responseData.errorMessage);
                console.error("Failed to delete project:\n", response.data.responseData.errorMessage);
            }
        }).catch(error => {
            setIsDeletingProject(false);
            errorMessage("Error deleting project:", error);
            console.error("Error deleting project:", error);
        });
    };

    const handleContainerChange = (value) => {
        setCurrentContainerId(value);
    };

    const successMessage = (message) => {
        messageApi.open({
            type: 'success',
            content: message,
        });
    };

    const warningMessage = (message) => {
        messageApi.open({
            type: 'warning',
            content: message,
        });
    };

    const errorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    const loadingMessage = (message) => {
        messageApi.open({
            type: 'loading',
            content: message,
            key: 'loadingMessage'
        });
    };


    return (
        <Layout style={{minHeight: '100vh'}}>
            {contextHolder}
            <Sider theme="light" style={{position: 'relative', height: '100vh'}} width={240}>
                <div className="sider-header" style={{padding: '16px', background: '#fff'}}>
                    <FormattedMessage id="sider.title" values={{versionNumber: userInfo.versionNumber}}/>
                </div>
                <Menu theme="light"
                      style={{borderRight: 0}}
                      items={siderMenuItems}
                      defaultSelectedKeys={['projects']}
                      mode="inline"
                      onClick={handleMenuClick}
                />
                <div style={{position: 'absolute', bottom: 0, width: '100%', padding: '16px', borderTop: '1px solid #f0f0f0'}}>
                    <Link onClick={handleLogout} style={{display: 'flex', alignItems: 'center'}}>
                        <LogoutOutlined/>
                        <span style={{marginLeft: '8px'}}>
                            <FormattedMessage id="sider.logout"/>
                        </span>
                    </Link>
                </div>
            </Sider>
            <Layout className="site-layout" style={{minHeight: '100vh', backgroundColor: '#f0f2f5'}}>
                <Content style={{margin: '0 16px', backgroundColor: '#f0f2f5'}}>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Title level={2}><FormattedMessage id="projectPage.title"/></Title>
                        <Space>
                            <DropDownMenuYear onYearChange={(year) => setSelectedYear(year)}/>
                            <DropDownMenuLanguage setLocale={setLocale}/>
                        </Space>
                    </div>
                    <div>
                        <Flex wrap="wrap" gap="middle">
                            {Object.entries(containerTypes).map(([key, value]) => {
                                const itemCount = containers
                                    .find(container => container.id === key)
                                    ?.items.filter(item => item.createToYear === selectedYear).length || 0;

                                return (
                                    <Button key={key}
                                            type={currentContainerId === key ? 'primary' : 'text'}
                                            onClick={() => handleContainerChange(key)}
                                            size='middle'
                                    >
                                        {value} ({isRenderingProjects ? '...' : itemCount})
                                    </Button>
                                );
                            })}
                            <Button icon={<FileAddOutlined/>}
                                    onClick={() => setShowAddProjectModal(true)}
                            >
                                <Space>
                                    <FormattedMessage id="projectPage.addProject"/>
                                </Space>
                            </Button>
                        </Flex>
                    </div>
                    <Modal title={<FormattedMessage id="projectPage.addProject"/>}
                           centered
                           width="550px"
                           open={showAddProjectModal}
                           onOk={() => setShowAddProjectModal(false)}
                           onCancel={() => {
                               setShowAddProjectModal(false);
                               setDrawingApproval(false);
                               setDrawingApprovalDate(null);
                               setWorkPermitApproval(false);
                               setWorkPermitDate(null);
                               setProjectCoordinates([]);
                               setTimeout(() => {
                                   addProjectForm.resetFields();
                               }, 300);
                           }}
                           footer={null}
                    >
                        <Form name="add-project-form"
                              form={addProjectForm}
                              onFinish={handleAddProject}
                        >
                            <Form.Item name="job-site"
                                       label={<FormattedMessage id="projectPage.jobSite"/>}
                                       rules={[{required: true, message: <FormattedMessage id="projectPage.jobSite.des"/>}]}
                            >
                                <AddressAutocomplete mapToken={mapToken}
                                                     onSelectAddress={(selected) => {
                                                         addProjectForm.setFieldsValue({'job-site': selected.value});
                                                         addProjectForm.setFieldsValue({'project-name': selected.shortAddress});
                                                         setProjectCoordinates(selected.coordinates);
                                                     }}
                                />
                            </Form.Item>
                            <Form.Item name="project-name"
                                       label={<FormattedMessage id="projectPage.projectName"/>}
                                       rules={[{required: true, message: <FormattedMessage id="projectPage.projectName.des"/>}]}
                            >
                                <Input placeholder={intl.formatMessage({id: "projectPage.projectName.req"})}/>
                            </Form.Item>
                            <Form.Item name="code-compliant" label={<FormattedMessage id="projectPage.code"/>}>
                                <Select placeholder={intl.formatMessage({id: "projectPage.code.des"})}>
                                    <Option value="ASME A17.1-2000, NYC 2014">ASME A17.1-2000, NYC 2014</Option>
                                    <Option value="ASME A17.1-2013, NYC 2022">ASME A17.1-2013, NYC 2022</Option>
                                    <Option value="ASME A17.1-2022">ASME A17.1-2022</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item name="contract-signed-date" label={<FormattedMessage id="projectPage.signedDate"/>}>
                                <DatePicker/>
                            </Form.Item>
                            {(currentContainerId === "container-in-progress" || currentContainerId === "container-completed") && (
                                <>
                                    <Form.Item name="project-start-date"
                                               label={<FormattedMessage id="projectPage.startDate"/>}
                                               rules={[{required: true, message: <FormattedMessage id="projectPage.startDate.req"/>}]}
                                    >
                                        <DatePicker/>
                                    </Form.Item>
                                </>
                            )}
                            {currentContainerId !== "container-on-going" && (
                                <>
                                    <Form.Item label={<FormattedMessage id="projectPage.drawingApprovedDate"/>}>
                                        <DatePicker name="drawing-approval-date"
                                                    disabled={!drawingApproval}
                                                    value={drawingApprovalDate}
                                                    onChange={(date) => setDrawingApprovalDate(date)}
                                        />
                                        <span style={{marginLeft: 20,}}>
                                                <Checkbox checked={drawingApproval}
                                                          onChange={(e) => {
                                                              setDrawingApproval(e.target.checked);
                                                              if (!e.target.checked) {
                                                                  setDrawingApprovalDate(null);
                                                              }
                                                          }}
                                                >
                                                    <FormattedMessage id="projectPage.approved"/>
                                                </Checkbox>
                                            </span>
                                    </Form.Item>
                                    <Form.Item label={<FormattedMessage id="projectPage.workPermitDate"/>}>
                                        <DatePicker name="work-permit-date"
                                                    disabled={!workPermitApproval}
                                                    value={workPermitDate}
                                                    onChange={(date) => setWorkPermitDate(date)}
                                        />
                                        <span style={{marginLeft: 20,}}>
                                                <Checkbox checked={workPermitApproval}
                                                          onChange={(e) => {
                                                              setWorkPermitApproval(e.target.checked);
                                                              if (!e.target.checked) {
                                                                  setWorkPermitDate(null);
                                                              }
                                                          }}
                                                >
                                                    <FormattedMessage id="projectPage.approved"/>
                                                </Checkbox>
                                            </span>
                                    </Form.Item>
                                </>
                            )}
                            {currentContainerId === "container-completed" && (
                                <>
                                    <Form.Item name="project-complete-date"
                                               label={<FormattedMessage id="projectPage.completeDate"/>}
                                               rules={[{required: true, message: <FormattedMessage id="projectPage.completeDate.req"/>}]}
                                    >
                                        <DatePicker/>
                                    </Form.Item>
                                </>
                            )}
                            <div style={{textAlign: 'right'}}>
                                <Space>
                                    <Button key="cancel"
                                            onClick={() => {
                                                setShowAddProjectModal(false);
                                                setDrawingApproval(false);
                                                setDrawingApprovalDate(null);
                                                setWorkPermitApproval(false);
                                                setWorkPermitDate(null);
                                                setTimeout(() => {
                                                    addProjectForm.resetFields();
                                                    addMaterialForm.setFieldsValue({materials: [{}]});
                                                }, 300);
                                            }}>
                                        <FormattedMessage id="main.cancel"/>
                                    </Button>
                                    <Button key="submit" type="primary" htmlType="submit" loading={isCreatingProject}>
                                        <FormattedMessage id="projectPage.addProject"/>
                                    </Button>
                                </Space>
                            </div>
                        </Form>
                    </Modal>
                    <div className="kanban-board">
                        {containers
                            .filter(container => container.id === currentContainerId)
                            .map((container) => {
                                const filteredItems = container.items.filter(item => {
                                    if (item.completeDate) {
                                        // Extract the year from completeDate
                                        const completeYear = dayjs(item.completeDate).format('YYYY');
                                        return completeYear === selectedYear;
                                    } else {
                                        // Fallback to createToYear
                                        return item.createToYear === selectedYear;
                                    }
                                });

                                return (
                                    <Container id={container.id}
                                               name={container.name}
                                               key={container.id}
                                               isRenderingProjects={isRenderingProjects}
                                    >
                                        {filteredItems.length > 0 ? (
                                            filteredItems.map((item) => (
                                                <Items key={item.id}
                                                       containerId={container.id}
                                                       id={item.id}
                                                       name={item.name}
                                                       jobSite={item.jobSite}
                                                       coordinates={item.coordinates}
                                                       installationGroup={item.installationGroup}
                                                       wiringGroup={item.wiringGroup}
                                                       deliveryGroup={item.deliveryGroup}
                                                       elevatorData={item.elevatorData}
                                                       code={item.code}
                                                       signDate={item.signDate}
                                                       createToYear={item.createToYear}
                                                       createDate={item.createDate}
                                                       startDate={item.startDate}
                                                       drawingApprovalDate={item.drawingApprovalDate}
                                                       workPermitDate={item.workPermitDate}
                                                       materialOrderDate={item.materialOrderDate}
                                                       materialArriveDate={item.materialArriveDate}
                                                       runPlatformDate={item.runPlatformDate}
                                                       doorDeliveredDate={item.doorDeliveredDate}
                                                       finishElevatorDate={item.finishElevatorDate}
                                                       acceptDate={item.acceptDate}
                                                       completeDate={item.completeDate}
                                                       dobStartDate={item.dobStartDate}
                                                       dobEndDate={item.dobEndDate}
                                                       dobDetails={item.dobDetails}
                                                       memoCount={item.memoCount}
                                                       percentage={item.percentage}
                                                       onDelete={handleDeleteProject}
                                                       isDeletingProject={isDeletingProject}
                                                       onEdit={handleEditProject}
                                                       onMove={handleMoveProject}
                                                       isEditingProject={isEditingProject}
                                                       isRenderingProjects={isRenderingProjects}
                                                       os={userInfo.os}
                                                       userId={userInfo.userId}
                                                />
                                            ))
                                        ) : (
                                            <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                <Empty style={{margin: '20px'}}/>
                                            </div>
                                        )}
                                    </Container>
                                );
                            })
                        }
                    </div>
                </Content>
            </Layout>
        </Layout>
    );
};

export default ProjectPage;
